import React from "react";
import PropTypes from "prop-types";
import styles from "./Pagination.module.scss";

const Pagination = ({ pagesCount, activePage }) => (
  <div className={styles.pagination}>
    {activePage}/{pagesCount}
  </div>
);

Pagination.propTypes = {
  pagesCount: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
};

export default Pagination;
