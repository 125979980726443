import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Button } from "@mui/material";
import { TextInput } from "tt-ui-kit";
import styles from "./SecondStep.module.scss";

import { ReactComponent as ConfDocSvg } from "../../assets/icons/confDoc.svg";
import MuiDatePicker from "../../components/muiDatePicker/MuiDatePicker";

const RegistrationProject = ({
  onChange,
  openDisclaimer,
  openFileModal,
  data,
  dateDisable,
}) => (
  <>
    <Button
      className={styles.disclaimerBtn}
      size="small"
      color="lightBlue"
      onClick={openDisclaimer}
    >
      Financial data*
    </Button>
    <TextInput
      className={clsx(styles.input, styles.wide)}
      label="Name *"
      name="name"
      value={((data && data.name) ?? "").toString()}
      onChange={onChange}
    />
    <TextInput
      className={clsx(styles.input, styles.normal)}
      label="Quantity *"
      name="uniqProduct"
      type="number"
      value={((data && data.uniqProduct) ?? "").toString()}
      onChange={onChange}
    />
    <MuiDatePicker
      className={clsx(styles.input, styles.wide)}
      label="Start period"
      name="reportDate"
      value={(data && data.reportDate) ?? null}
      onChange={onChange}
      minDate={new Date(2000, 1, 1)}
      maxDate={data.reportDateEnd ?? new Date(new Date().getFullYear(), 11, 31)}
      disabled={dateDisable}
      required={!!data.reportDate || !!data.reportDateEnd}
    />
    <MuiDatePicker
      className={clsx(styles.input, styles.wide)}
      label="End period"
      name="reportDateEnd"
      value={(data && data.reportDateEnd) ?? null}
      onChange={onChange}
      minDate={data.reportDate ?? new Date(2000, 1, 1)}
      maxDate={new Date(new Date().getFullYear(), 11, 31)}
      disabled={dateDisable}
      required={!!data.reportDate || !!data.reportDateEnd}
    />
    <Button
      className={clsx(styles.docBtn, styles.normal)}
      startIcon={<ConfDocSvg />}
      onClick={openFileModal}
    >
      CONFIRMATION DOC
    </Button>
  </>
);

RegistrationProject.propTypes = {
  onChange: PropTypes.func.isRequired,
  openDisclaimer: PropTypes.func.isRequired,
  openFileModal: PropTypes.func.isRequired,
  numberFilter: PropTypes.instanceOf(RegExp),
  data: PropTypes.object,
  dateDisable: PropTypes.bool,
};

export default RegistrationProject;
