import React from "react";
import PropTypes from "prop-types";

import { Checkbox, FormControlLabel } from "@mui/material";
import CheckBoxOutlined from "@mui/icons-material/CheckBoxOutlined";

const MuiCheckBox = ({ name, label, value, className, onChange }) => (
  <FormControlLabel
    className={className}
    control={
      <Checkbox
        name={name}
        onChange={onChange}
        checkedIcon={<CheckBoxOutlined />}
        variant="unlighted"
        checked={value || false}
        /* defaultChecked={false} вызывал ошибку "A component 
        is changing the default checked state of 
        an uncontrolled SwitchBase after being initialized..." */
      />
    }
    label={label}
    labelPlacement="end"
  />
);

MuiCheckBox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

MuiCheckBox.defaultProps = {
  label: "",
  value: false,
  className: "",
  onChange: () => {},
};

export default MuiCheckBox;
