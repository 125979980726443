import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { TextField, Tooltip, InputAdornment } from "@mui/material";

const MuiTextField = ({
  name,
  label,
  value,
  onChange,
  filter,
  className,
  required,
  sx,
  tooltipText,
  startIcon,
  endIcon,
  onClick,
  readOnly,
  blockWrongTyping,
  ...restProps
}) => {
  const [isEmptied, setIsEmptied] = useState(false);
  const [isValidated, setIsValidated] = useState(true);
  const [inputValue, setInputValue] = useState(value);
  const [timeoutID, setTimeoutID] = useState(null);

  useEffect(() => setInputValue(value), [value]);

  const DebouncedChange = (e) => {
    if (!e?.target) return;
    setIsEmptied(e.target.value === "" || e.target.value === null);
    setIsValidated(filter ? filter.test(e.target.value) : true);
    onChange(e);
  };

  const handleChange = (e) => {
    if (blockWrongTyping && filter && !filter.test(e.target.value)) return;

    setInputValue(e.target.value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const newTimeoutId = setTimeout(() => {
      DebouncedChange(e);
    }, 300);
    setTimeoutID(newTimeoutId);
  };

  const errorCheck = () => isEmptied || !isValidated;
  const getHelperText = () => {
    if (isEmptied) return "This is a required field. Please fill in this field";
    if (!isValidated) return "Incorrect value";
    return "";
  };

  return (
    <Tooltip title={`${tooltipText}`}>
      <TextField
        className={className}
        label={label}
        name={name}
        value={inputValue ?? ""}
        onChange={handleChange}
        helperText={required ? getHelperText() : ""}
        error={required ? errorCheck() : false}
        sx={sx}
        onClick={onClick}
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
          classes: { root: value !== "" && "filled" },
        }}
        readOnly={readOnly}
        {...restProps}
      />
    </Tooltip>
  );
};

MuiTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  required: PropTypes.bool,
  filter: PropTypes.instanceOf(RegExp),
  sx: PropTypes.object,
  tooltipText: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  readOnly: PropTypes.bool,
  valueLength: PropTypes.number,
  blockWrongTyping: PropTypes.bool,
};

MuiTextField.defaultProps = {
  label: "",
  value: "",
  onChange: () => {},
  onClick: () => {},
  className: "",
  filter: null,
  required: true,
  sx: null,
  tooltipText: "",
  readOnly: false,
  inputProps: {},
  blockWrongTyping: true,
};

export default MuiTextField;
