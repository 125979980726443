/* eslint-disable camelcase */
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal, TextInput } from "tt-ui-kit";
import { Button } from "@mui/material";
import FileUploader from "../../../components/fileUploader/FileUploader";
import styles from "./ModalQuestion.module.scss";

const ModalCreateQuestion = ({ params }) => {
  const {
    addItem,
    editQuestionId,
    parentTopicId,
    generateAnswer,
    isOpen,
    setIsOpen,
  } = params;
  const [editQuestionText, setEditQuestionText] = useState("");
  const [edited, setEdited] = useState(false);

  const onSave = () => {
    setEdited(false);
    addItem({
      typeItem: "question",
      parentId: parentTopicId,
      payload: { question_text: editQuestionText },
    });
  };

  const onEdit = (value) => {
    setEdited(true);
    setEditQuestionText(value);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      closeOnlyByControls
      title={
        <div className={styles.headerModal}>
          <div>Add question</div>
        </div>
      }
      className={styles.modal}
      /* eslint-disable-next-line no-underscore-dangle */
      containerClassName={clsx(styles.__modalRoot, styles.modalWide)}
    >
      <div className={styles.modalContent}>
        <TextInput
          name="question"
          label="Question"
          className={styles.questionInput}
          inputProps={{ maxLength: 1800 }}
          value={editQuestionText}
          onChange={(e) => onEdit(e.target.value)}
          multiline
          maxRows={10}
          minRows={3}
        />
      </div>
      <div className={styles.modalButtonsBlock}>
        <Button variant="outlined" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <FileUploader
          questionCode={editQuestionId ?? ""}
          btnText={"Add documents"}
          btnType={"default"}
          disabled={!editQuestionId}
        />
        <Button
          variant="outlined"
          onClick={() => generateAnswer(editQuestionId)}
          disabled={!editQuestionId}
        >
          Generate answer
        </Button>
        {edited ? (
          <Button variant="contained" onClick={onSave}>
            Save
          </Button>
        ) : (
          <Button variant="contained" onClick={() => setIsOpen(false)}>
            Ok
          </Button>
        )}
      </div>
    </Modal>
  );
};

ModalCreateQuestion.propTypes = {
  params: {
    editQuestionId: PropTypes.string,
    parentTopicId: PropTypes.string,
    addItem: PropTypes.func,
    generateAnswer: PropTypes.func,
  },
};

export default ModalCreateQuestion;
