import clsx from "clsx";
import React, { useContext, useState, useEffect } from "react";
import { v1 as uuidv1 } from "uuid";
import { useLazyQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import axios from "axios";

import { Input } from "@mui/material";
import { Button, Modal } from "tt-ui-kit";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import BlockIcon from "@mui/icons-material/Block";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";

import DropArea from "./DropArea";

import { ReactComponent as DropBoxSvgG } from "../../assets/icons/dropBoxIcoGray.svg";
import { ReactComponent as GdriveSvgG } from "../../assets/icons/gdriveIcoGray.svg";
import { ReactComponent as IcloudSvgG } from "../../assets/icons/iCloudIcoGray.svg";
import { ReactComponent as ConfDocSvg } from "../../assets/icons/confDoc.svg";

import { validateFile } from "../../utils";
import { acceptedExtentions } from "../../constants";
import UserContext from "../../context/User/userContext";
import styles from "./FileUploader.module.scss";

import {
  DELETE_QUESTIONS_FILE,
  GET_URL_FOR_PRIVATE_UPLOAD,
  ADD_UPLOADED_FILE_TO_QUESTION,
  GET_QUESTIONS_FILE,
} from "../../api";

// const NAME_LENGTH_LIMIT = 14;
// const SIZE_LIMIT = 2048000;

const STATUSES = {
  UPLOADING_STATUS: "uploading",
  UPLOADED_STATUS: "uploaded",
  ERROR_STATUS: "error",
};

const FileUploader = ({ questionCode, disabled, btnType, btnText }) => {
  const { draftId } = useContext(UserContext);

  const [deleteFile] = useMutation(DELETE_QUESTIONS_FILE);
  const [getPrivateUrl] = useMutation(GET_URL_FOR_PRIVATE_UPLOAD);
  const [uploadFileToQuestion] = useMutation(ADD_UPLOADED_FILE_TO_QUESTION);
  const [getFiles, { data: filesData }] = useLazyQuery(GET_QUESTIONS_FILE, {
    variables: { input: { draft_id: draftId, questions_code: questionCode } },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesList, setFilesList] = useState([]);

  useEffect(() => {
    if (!filesToUpload?.length) getFiles();
  }, [filesToUpload]);

  useEffect(() => {
    setFilesList([]);
  }, [questionCode]);

  useEffect(() => {
    if (!filesData?.getQuestionsFile) return;
    const rejectedFiles = filesList.filter(
      (file) => file.status === STATUSES.ERROR_STATUS
    );
    const savedFiles = filesData.getQuestionsFile;
    setFilesList([
      ...rejectedFiles.map((f) => ({ ...f, status: STATUSES.ERROR_STATUS })),
      ...savedFiles.map((f) => ({ ...f, status: STATUSES.UPLOADED_STATUS })),
    ]);
  }, [filesData]);

  const updateState = (uuidName, status) => {
    const updatedFile = filesList.find((file) => file.uuidName === uuidName);
    return { ...updatedFile, status };
  };

  const uploadFile = async (file) => {
    try {
      const privateUrl = await getPrivateUrl({
        variables: {
          input: {
            type: file.type,
            name: file.uuidName,
          },
        },
      });
      const url = privateUrl.data?.getUrlForPrivateUpload?.url;
      if (!url) throw new Error("no url");
      const putData = await axios.put(url, file.f, {
        headers: {
          "Content-type": file.f.type,
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (putData) {
        // отправка на наш сервак
        const saveResult = await uploadFileToQuestion({
          variables: {
            input: {
              questions_code: questionCode,
              calc_tt_drafts_id: draftId,
              type: file.type,
              original_name: file.originalName,
              file: file.uuidName,
            },
          },
        });
        if (saveResult) {
          return updateState(file.uuidName, STATUSES.UPLOADED_STATUS);
        }
      }
      return updateState(file.uuidName, STATUSES.ERROR_STATUS);
    } catch (error) {
      return updateState(file.uuidName, STATUSES.ERROR_STATUS);
    }
  };

  const saveQuestionFiles = async () => {
    // поставить экран загрузки
    const arr = [];
    filesToUpload.forEach((file) => {
      arr.push(uploadFile(file, questionCode));
    });
    const updated = await Promise.all(arr);
    // снять экран загрузки
    setFilesList(
      filesList.map((file) =>
        updated.some((ufile) => ufile.uuidName === file.uuidName)
          ? updated.find((ufile) => ufile.uuidName === file.uuidName)
          : file
      )
    );
    setFilesToUpload([]);
  };

  const deleteQuestionsFile = async (fileId) => {
    try {
      const { data } = await deleteFile({
        variables: {
          id: fileId,
        },
      });
      if (data.deleteQuestionsFile) {
        const actualFilesList = filesList.filter((file) => file.id !== fileId);
        setFilesList(actualFilesList);
      }
    } catch (_) {
      // TODO: показывать ошибку, если запрос не прошёл
      // см. комментарий в Local.jsx
    }
  };

  const getFileType = (ext) => {
    if (acceptedExtentions.acceptedImages.includes(ext)) return "image";
    if (acceptedExtentions.acceptedDocs.includes(ext)) return "doc";
    return "";
  };

  const updateFilesList = (fList) => {
    const newList = [];
    for (let i = 0; i < fList.length; i += 1) {
      newList.push(fList[i]);
    }
    const actualFilesList = newList.reduce((arr, f) => {
      const fileNameSplitted = f.name.split(".");
      const extension = fileNameSplitted[fileNameSplitted.length - 1];
      const uuidName = `${uuidv1()}.${extension}`;
      const newFiles = [
        ...arr,
        {
          f,
          originalName: f.name,
          uuidName,
          status:
            f.error || !validateFile(extension, f.size ?? 0)
              ? STATUSES.ERROR_STATUS
              : STATUSES.UPLOADING_STATUS,
          type: getFileType(extension),
        },
      ];
      return newFiles;
    }, []);
    setFilesList([...actualFilesList, ...filesList]);
    setFilesToUpload([
      ...actualFilesList.filter((f) => f.status === STATUSES.UPLOADING_STATUS),
    ]);
  };

  useEffect(() => {
    if (!filesToUpload.length) return;
    saveQuestionFiles();
  }, [filesToUpload]);

  const removeFileFromList = (idx) => {
    const fileForRemove = filesList.filter((file, i) => i === idx && file.id);
    if (fileForRemove.length > 0 && fileForRemove[0].id) {
      deleteQuestionsFile(fileForRemove[0].id);
    } else {
      const actualFilesList = filesList.filter((file, i) => i !== idx);
      setFilesList(actualFilesList);
    }
  };

  const onInputChange = (e) => {
    updateFilesList(e.target.files);
  };

  const fileStatusStyle = (file) => {
    if (file.status === STATUSES.ERROR_STATUS) return styles.rejected;
    if (file.status === STATUSES.UPLOADED_STATUS) return styles.accepted;
    return styles.loading;
  };

  const getFileStatusIcon = (file) => {
    if (file.status === STATUSES.ERROR_STATUS) return <BlockIcon />;
    if (file.status === STATUSES.UPLOADED_STATUS) return <TaskOutlinedIcon />;
    return <RestorePageOutlinedIcon />;
  };

  return (
    <>
      <Button
        type={btnType ?? "link"}
        startIcon={<ConfDocSvg />}
        onClick={() => setIsModalOpen((value) => !value)}
        disabled={disabled}
        className={styles.docButton}
      >
        {btnText ?? "CONFIRMATION DOC"}
      </Button>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onClick={(e) => e.stopPropagation()}
          title="Upload your confirmation docs"
          className={styles.modal}
          closeOnlyByControls
          /* eslint-disable-next-line no-underscore-dangle */
          containerClassName={clsx(styles.__modalRoot, styles.modalWide)}
        >
          <div className={styles.columnWrapper}>
            <div className={styles.rowWrapper}>
              <DropArea onDrop={updateFilesList}></DropArea>
              <div className={styles.buttonWrapper}>
                <Input
                  type="file"
                  className={styles.input}
                  onChange={onInputChange}
                  multiple="multiple"
                  id="icon-button-file"
                  style={{ display: "none" }}
                />
                <label htmlFor="icon-button-file">
                  <Button
                    type="link"
                    component="span"
                    size="small"
                    startIcon={<FileUploadIcon />}
                  >
                    Choose the file
                  </Button>
                </label>
                <Button
                  type="link"
                  component="span"
                  size="small"
                  startIcon={<DropBoxSvgG />}
                  disabled={true}
                >
                  UPLOAD FROM DROPBOX
                </Button>
                <Button
                  className={styles.disabled}
                  type="link"
                  component="span"
                  size="small"
                  startIcon={<GdriveSvgG />}
                  disabled={true}
                >
                  UPLOAD FROM GOOGLE DRIVE
                </Button>
                <Button
                  className={styles.disabled}
                  type="link"
                  component="span"
                  size="small"
                  startIcon={<IcloudSvgG />}
                  disabled={true}
                >
                  UPLOAD FROM ICLOUD
                </Button>
              </div>
            </div>
            <div className={styles.columnWrapper}>
              <div>
                {filesList.length > 0 ? (
                  <div>
                    {filesList.map((file, idx) => (
                      <li
                        key={idx}
                        // title={validateMessage(file)}
                        title="validate"
                        className={fileStatusStyle(file)}
                      >
                        <div>
                          <span>{getFileStatusIcon(file)}</span>
                          {file.originalName}
                          <span>
                            <Button
                              type="icon"
                              onClick={() => removeFileFromList(idx)}
                            >
                              х
                            </Button>
                          </span>
                        </div>
                      </li>
                    ))}
                  </div>
                ) : (
                  "No files yet"
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

FileUploader.propTypes = {
  questionCode: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  btnType: PropTypes.string,
  btnText: PropTypes.string,
};

export default FileUploader;
