import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Button } from "@mui/material";

import styles from "./SecondStep.module.scss";

import MuiTextField from "../../components/muiTextField/MuiTextField";
import MuiDatePicker from "../../components/muiDatePicker/MuiDatePicker";

import { ReactComponent as ConfDocSvg } from "../../assets/icons/confDoc.svg";

const SecondStep = ({
  onChange,
  onChangeDate,
  openDisclaimer,
  openFileModal,
  numberFilter,
  data,
  // years,
  reportYear,
  dateDisable,
}) => (
  <>
    <Button
      className={styles.disclaimerBtn}
      size="small"
      color="lightBlue"
      onClick={openDisclaimer}
    >
      Financial data*
    </Button>
    <MuiTextField
      className={clsx(styles.input, styles.wide)}
      label="Annual turnover (USD) *"
      name="turnover"
      filter={numberFilter}
      value={((data && data.turnover) ?? "").toString()}
      onChange={onChange}
      inputProps={{ maxLength: 20 }}
    />
    <MuiTextField
      className={clsx(styles.input, styles.normal)}
      label="Net profit (USD) *"
      name="profit"
      filter={numberFilter}
      value={((data && data.profit) ?? "").toString()}
      onChange={onChange}
      inputProps={{ maxLength: 20 }}
    />
    <MuiTextField
      className={clsx(styles.input, styles.normal)}
      label="Taxes paid for the year (USD) *"
      name="taxes"
      filter={numberFilter}
      value={((data && data.taxes) ?? "").toString()}
      onChange={onChange}
      inputProps={{ maxLength: 20 }}
    />
    <MuiTextField
      className={clsx(styles.input, styles.wide)}
      label="Number of unique product *"
      name="uniqProduct"
      filter={numberFilter}
      value={((data && data.uniqProduct) ?? "").toString()}
      onChange={onChange}
      inputProps={{ maxLength: 20 }}
    />
    <MuiTextField
      className={clsx(styles.input, styles.normal)}
      label="The number of employees *"
      name="employees"
      filter={numberFilter}
      value={((data && data.employees) ?? "").toString()}
      onChange={onChange}
      inputProps={{ maxLength: 20 }}
    />
    <MuiTextField
      className={clsx(styles.input, styles.normal)}
      label="Annual productivity for each position *"
      name="productivity"
      filter={numberFilter}
      value={((data && data.productivity) ?? "").toString()}
      onChange={onChange}
      inputProps={{ maxLength: 20 }}
    />
    <MuiDatePicker
      className={clsx(styles.input, styles.wide)}
      label="Reporting year *"
      name="reportDate"
      value={reportYear ? new Date(reportYear, 1, 1) : null}
      onChange={onChangeDate}
      minDate={new Date(2000, 1, 1)}
      maxDate={new Date(new Date().getFullYear(), 11, 31)}
      onlyYear={true}
      disabled={dateDisable}
      type="number"
    />
    <Button
      className={clsx(styles.docBtn, styles.normal)}
      startIcon={<ConfDocSvg />}
      onClick={openFileModal}
    >
      CONFIRMATION DOC
    </Button>
  </>
);

SecondStep.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  openDisclaimer: PropTypes.func.isRequired,
  openFileModal: PropTypes.func.isRequired,
  numberFilter: PropTypes.instanceOf(RegExp),
  data: PropTypes.object,
  years: PropTypes.array,
  reportYear: PropTypes.number,
  dateDisable: PropTypes.bool,
};

export default SecondStep;
