import React from "react";
import PropTypes from "prop-types";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import styles from "./index.module.scss";
import FileUploader from "../../../components/fileUploader/FileUploader";

const InputQuestion = ({ text, answer, setAnswer, fileCode }) => {
  const numberFilter = /^(\d+\.)?\d+$/;

  return (
    <div className={styles.question}>
      <div
        className={styles.questionText}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <MuiTextField
        className={styles.inputAnswer}
        label={"Enter your data"}
        name="data"
        value={(answer || "").toString()}
        onChange={(e) => setAnswer(parseFloat(e.target.value))}
        filter={numberFilter}
        inputProps={{ maxLength: 20 }}
      />
      <div className={styles.footerAnswer}>
        <FileUploader questionCode={fileCode} disabled={false} />
      </div>
    </div>
  );
};

InputQuestion.propTypes = {
  answer: PropTypes.number,
  text: PropTypes.string.isRequired,
  setAnswer: PropTypes.func.isRequired,
  openFileModal: PropTypes.func,
  fileCode: PropTypes.string,
};

export default InputQuestion;
