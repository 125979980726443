import Cookies from "js-cookie";
import {
  CompanyQuestionsEnabledFields,
  acceptedExtentions,
  CalculatorsListData,
} from "./constants";

export const isDraftFilled = (draft) =>
  !!draft.address1 &&
  !!draft.companyId &&
  !!draft.companyName &&
  !!draft.countryId &&
  !!draft.zipCode &&
  !!draft.establishedDate &&
  !!draft.id &&
  ((draft.type !== "global" && !!draft.industrialGroupId) ||
    (draft.type === "global" && !!draft.subIndustryId)) &&
  !!draft.legalTypeId &&
  !!draft.name &&
  !!draft.reportDate &&
  (!CalculatorsListData[draft.type].requiredFields.numberFields ||
    CalculatorsListData[draft.type].requiredFields.numberFields.every(
      (f) => !!draft[f]
    )) &&
  (!CalculatorsListData[draft.type].requiredFields.stringFields ||
    CalculatorsListData[draft.type].requiredFields.stringFields.every(
      (f) => !!draft[f]
    ));

// !!draft.productivity &&
// !!draft.profit &&
// !!draft.taxes &&
// !!draft.turnover &&
// !!draft.uniqProduct &&
// !!draft.employees &&

export const initCompanyAnswer = (code) => {
  const answer = {};
  CompanyQuestionsEnabledFields[code].forEach((ckey) => {
    const key = ckey.split("_")[0];
    answer[key] = "";
  });
  answer.scopeId = "";
  answer.data = "";
  answer.info = "";
  answer.filled = false;
  answer.isNotAvailable = false;
  return answer;
};

export const initCompanyAnswers = () => {
  const answers = {};
  Object.keys(CompanyQuestionsEnabledFields).forEach((code) => {
    answers[code] = [];
    answers[code].push(initCompanyAnswer(code));
  });
  return answers;
};

/* eslint-disable import/prefer-default-export */
export const formatDate = (date) => {
  if (typeof date === "number" && date === parseInt(date, 10)) {
    return `${date}-01-01 00:00:00`;
  }
  const newDate = new Date(date);
  if (!Number.isNaN(newDate.getTime())) {
    const isoDate = newDate.toISOString().split("T")[0];
    return `${isoDate} 00:00:00`;
  }
  return "";
};

/* eslint-disable import/prefer-default-export */
export const formatDateTime = (date) => {
  if (!(date instanceof Date)) return "";

  const isoDate = date.toISOString().split("T")[0];
  const isoTime = date.toISOString().split("T")[1].split(".")[0];
  return `${isoDate} ${isoTime}`;
};

export const firstLetterToUppercase = (s) => s[0].toUpperCase() + s.slice(1);

export const mergeUserWithDraft = (user, draft) => ({
  ...user,
  countryId: draft.countryId ? draft.countryId : "",
  companyId: draft.companyId ? draft.companyId : "",
  establishedDate: new Date(draft.establishedDate),
  legalTypeId: draft.legalTypeId ? draft.legalTypeId : "",
  address1: draft.address1 ? draft.address1 : "",
  address2: draft.address2 ? draft.address2 : "",
  zipCode: draft.zipCode ? draft.zipCode : "",
  industrialGroupId: draft.industrialGroupId ? draft.industrialGroupId : "",
  turnover: draft.turnover ? draft.turnover : "",
  profit: draft.profit ? draft.profit : "",
  taxes: draft.taxes ? draft.taxes : "",
  uniqProduct: draft.uniqProduct ? draft.uniqProduct : "",
  productivity: draft.productivity ? draft.productivity : "",
  employees: draft.employees ? draft.employees : "",
  reportDate: new Date(draft.reportDate),
  type: draft.type,
});

export const mergeDraftWithUser = (user, draft) => ({
  ...draft,
  userId: user.id,
  email: user.email,
  countryId: user.countryId,
  companyId: user.companyId,
  establishedDate: new Date(user.establishedDate),
  legalTypeId: user.legalTypeId,
  address1: user.address1,
  address2: user.address2,
  zipCode: user.zipCode,
  industrialGroupId: user.industrialGroupId,
  turnover: user.turnover,
  profit: user.profit,
  taxes: user.taxes,
  uniqProduct: user.uniqProduct,
  productivity: user.productivity,
  employees: user.employees,
  reportDate: new Date(user.reportDate),
});

export const validateMessage = (file) => {
  if (
    !acceptedExtentions.acceptedImages.includes(file.name.split(".").pop()) &&
    !acceptedExtentions.acceptedDocs.includes(file.name.split(".").pop())
  )
    return `valid file extension is: ${acceptedExtentions.acceptedImages.join(
      ", "
    )} or ${acceptedExtentions.acceptedDocs.join(", ")}`;
  if (file.size > 1e8) return "Maximum file size is 100Mb";
  return "";
};

export const validateFile = (ext, size) =>
  (acceptedExtentions.acceptedImages.includes(ext) ||
    acceptedExtentions.acceptedDocs.includes(ext)) &&
  size < 1e8;

export const separateFiles = (actualFilesList) => {
  const images = [];
  const docs = [];
  actualFilesList.forEach((file) => {
    const fileExt = file.name.split(".").pop();
    if (
      acceptedExtentions.acceptedImages.includes(fileExt) &&
      validateFile(file)
    )
      images.push(file);
    if (acceptedExtentions.acceptedDocs.includes(fileExt) && validateFile(file))
      docs.push(file);
  });
  return { images, docs };
};

export const fillCompany = (company) =>
  Object.keys(company).reduce((result, key) => {
    const components = company[key].map((component) => {
      const keys = Object.keys(component).filter(
        (ckey) => !["isNotAvailable", "info"].includes(ckey)
      );
      return {
        ...component,
        filled:
          !!component.info ||
          keys.reduce((res, k) => res && !!component[k], true),
      };
    });

    return {
      ...result,
      [key]: components,
    };
  }, {});

export const fillProductPackage = (product) =>
  product.map((source) => {
    const components = source.components.map((component) => ({
      ...component,
      filled:
        !!component.activity &&
        !!component.scope_id &&
        !!component.type &&
        !!component.unit &&
        !!component.additional &&
        !!component.data,
    }));
    return {
      name: source.name || source.source_name,
      id: source.id,
      components,
    };
  });

/**
 * рекурсивная функция для изменения свойств обьектов в camelCase
 * @param {*} obj - обект со свойствами в виде snake_case
 * @returns - обект со свойствами в виде camelCase
 */
export const snakeToCamel = (obj) => {
  const newObj = Object.keys(obj).reduce(
    (res, prop) => {
      const newPropName = prop.replaceAll(
        /._./g,
        (match) => `${match.split("_")[0]}${match.split("_")[1].toUpperCase()}`
      );
      const newPropValue =
        obj[prop] && typeof obj[prop] === "object"
          ? snakeToCamel(obj[prop])
          : obj[prop];
      return Array.isArray(obj)
        ? [...res, newPropValue]
        : { ...res, [newPropName]: newPropValue };
    },
    Array.isArray(obj) ? [] : {}
  );
  return newObj;
};

export const openAppLoader = () => {
  const loader = document.getElementById("loadingsScreen");
  if (loader) {
    loader.style.opacity = 1;
    loader.style.visibility = "visible";
  }
};

export const closeAppLoader = () => {
  const loader = document.getElementById("loadingsScreen");
  if (loader) {
    loader.style.opacity = 0;
    loader.style.visibility = "hidden";
  }
};

export const logoutUserHandler = (logoutApiMethod, disconnectSocketMethod) => {
  openAppLoader();

  logoutApiMethod()
    .then((response) => {
      if (response.data.logout) {
        const domainValue = process.env.REACT_APP_DOMAIN;
        Cookies.remove("access_token", { domain: domainValue });
        Cookies.remove("userId", { domain: domainValue });
        Cookies.remove("calculatorId", { domain: domainValue });
        if (disconnectSocketMethod) disconnectSocketMethod();
        window.location.href = "/";
      } else {
        closeAppLoader();
      }
    })
    .catch(() => {
      closeAppLoader();
      const domainValue = process.env.REACT_APP_DOMAIN;
      Cookies.remove("access_token", { domain: domainValue });
      Cookies.remove("userId", { domain: domainValue });
      Cookies.remove("calculatorId", { domain: domainValue });
      if (disconnectSocketMethod) disconnectSocketMethod();
      window.location.href = "/";
    });
};
