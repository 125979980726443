import React, { useContext, useEffect, useState } from "react";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { useLazyQuery } from "@apollo/client";
import { Button, Tooltip } from "@mui/material";
import { CalculatorsContext, UserContext } from "../../context";
import styles from "./CalculationCost.module.scss";
import { GET_PRICE } from "../../api";

const tooltipText = `
  Stock exchanges undertake a range of activities and fulfil a number of functions. When defining stock exchanges, their role is typically captured in terms of their listing services and trading function (or liquidity provision). An important related function is that of price formation.
  While vitally important, price formation is often not mentioned explicitly—partly perhaps because it is much less tangible, and partly because it is (sometimes) assumed to be part of the trading function. Indeed, trading and price formation are closely related—but more on this later.
  So what do we mean by price formation?
  In our report we turned to the well-established economics literature on market microstructure to understand the price formation process and its benefits to the economy.
  Price formation is an information-gathering process which ensures that market participants know enough about the prices of the assets being traded in the market, so that they can make well-informed decisions.
  `;

const CalculationCost = () => {
  const {
    calculatorPrices,
    calculatorName,
    company,
    calculatorAnswers,
    selectedCalculators,
  } = useContext(CalculatorsContext);

  const { draftId } = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const [cost, setCost] = useState(null);

  const { tariffCode, tariffName, attemptsLeft } = calculatorPrices;

  const [getPrice, { data: priceData }] = useLazyQuery(GET_PRICE);

  useEffect(() => {
    if (priceData) {
      setCost({ ...priceData.getQuickCalculatorPrice });
    }
  }, [priceData]);

  useEffect(() => {
    const isDiscount = !!attemptsLeft;
    const calcs = [];

    const companyFilledAnswersCount = Object.keys(company).reduce(
      (res, question) =>
        res + company[question].filter((activ) => activ.filled).length,
      0
    );

    if (Object.keys(calculatorAnswers).length) calcs.push("local");
    if (companyFilledAnswersCount > 0) calcs.push("carbon");
    getPrice({
      variables: {
        input: {
          draft_id: draftId,
          calculators: calcs,
          is_discount: isDiscount ? "1" : "0",
          carbon_count: `${companyFilledAnswersCount}`,
        },
      },
    });
  }, [company, calculatorAnswers, selectedCalculators]);

  const getTextBlock = () => {
    if (!cost || !calculatorName || !calculatorPrices) return <></>;

    const tariffString = (
      <>
        {" Your current plan:"}
        <span>&nbsp;{tariffName}.</span>
      </>
    );
    const chargeString =
      tariffCode !== "free" ? (
        <>
          {" You have"}
          <span>&nbsp;{attemptsLeft}&nbsp;</span>
          {" of charge calculation."}
        </>
      ) : (
        ""
      );
    const worthString =
      tariffCode === "standard" && cost.baseCost !== 0 ? (
        <>
          {" That worth"}
          <span>&nbsp;${cost.baseCost}.</span>
        </>
      ) : (
        ""
      );
    const costString =
      cost.finalCost > 0 ? (
        <>
          {" The calculation cost:"}
          <span>&nbsp;${cost.finalCost}.</span>
        </>
      ) : (
        ""
      );
    return (
      <>
        {!!tariffString && tariffString}
        {!!chargeString && chargeString}
        {!!worthString && worthString}
        {!!costString && costString}
      </>
    );
  };

  return (
    <>
      {cost && (
        <div className={styles.calculatorCostOuterBlock}>
          <div className={styles.calculatorCostInnerBlock}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <>
                <div className={styles.calculatorCostText}>
                  <div className={styles.tariffText}>
                    <div className={styles.calculatorCostText}>
                      {getTextBlock()}
                      <div className={styles.calculatorCostIco}>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          arrow
                          placement="top-end"
                          onClose={() => setOpen(false)}
                          open={open}
                          // disableFocusListener
                          disableHoverListener
                          // disableTouchListener
                          title={tooltipText}
                        >
                          <Button onClick={() => setOpen((value) => !value)}>
                            <HelpOutlineRoundedIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </ClickAwayListener>
          </div>
        </div>
      )}
    </>
  );
};

export default CalculationCost;
