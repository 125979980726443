import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_DRAFT, CREATE_COMPANY } from "../api";
import { formatDate } from "../utils";

import UserContext from "../context/User/userContext";
import CalculatorsContext from "../context/Calculators/calculatorsContext";
import ApiMethodContext from "./apiMethodContext";

function ApiMethodService({ children }) {
  const [updateDraft] = useMutation(UPDATE_DRAFT);
  const [createCompany] = useMutation(CREATE_COMPANY);

  const {
    draftId,
    draftsList,
    companies,
    user,
    changeNewCompanyData,
    changeUserData,
    setSelectedCompanyId,
  } = useContext(UserContext);

  const { state, calculatorName, chartData } = useContext(CalculatorsContext);

  const saveNewCompanyData = async () => {
    if (user.companyId === "newUserCompanyId" && companies.newUserCompanyId) {
      const newCompany = {
        company_name: companies.newUserCompanyId.companyName,
        user_id: user.id,
        country_id: user.countryId,
        legal_type_id: user.legalTypeId,
        industry_group_id: user.industrialGroupId,
        sub_industry_id: user.subIndustryId,
        address1: user.address1,
        address2: user.address2,
        zip_code: user.zipCode,
        email: user.email,
        established_date: formatDate(
          companies.newUserCompanyId.establishedDate
        ),
      };

      try {
        const { data } = await createCompany({
          variables: {
            input: {
              ...newCompany,
            },
          },
        });
        if (data.createCompany) {
          changeNewCompanyData({
            address1: newCompany.address1,
            address2: newCompany.address2,
            companyName: companies.newUserCompanyId.companyName,
            countryId: user.countryId,
            email: user.email,
            establishedDate: formatDate(
              companies.newUserCompanyId.establishedDate
            ),
            id: data.createCompany.id,
            industrialGroupId: user.industrialGroupId,
            subIndustryId: user.subIndustryId,
            legalTypeId: user.legalTypeId,
            userId: user.id,
            zipCode: user.zipCode,
          });
          changeUserData({ companyId: data.createCompany.id });
          setSelectedCompanyId(data.createCompany.id);
        }
      } catch (_) {
        // TODO: показывать ошибку, если запрос не прошёл
        // см. комментарий в Local.jsx
      }
    }
  };

  const GetProductPackageData = (result) =>
    result
      .filter(
        (source) =>
          (!!source.name || !!source.source_name) &&
          source.components.length &&
          source.components.some((component) => component.filled)
      )
      .map((source) => {
        const components = source.components
          .filter((component) => component.filled)
          .map((component) => ({
            scope_id: component.scope_id,
            activity: component.activity,
            type: component.type,
            unit: component.unit,
            additional: component.additional,
            data: component.data,
            info: component.info,
          }));
        return {
          source_name: source.name || source.source_name,
          components,
        };
      });

  const GetCompanyData = (company) =>
    Object.keys(company).reduce((result, key) => {
      const components = company[key]
        .filter((component) => {
          const keys = Object.keys(component).filter(
            (ckey) => !["isNotAvailable", "info", "filled"].includes(ckey)
          );
          return (
            !!component.info ||
            keys.reduce((res, k) => res && !!component[k], true)
          );
        })
        .map((component) => {
          const cloned = {
            ...component,
          };
          delete cloned.scopeId;
          delete cloned.filled;
          delete cloned.isNotAvailable;
          return cloned;
        });

      return {
        ...result,
        [key]: components,
      };
    }, {});

  const completeDraft = async () => {
    try {
      await updateDraft({
        variables: {
          input: {
            id: draftId,
            completed_at: formatDate(new Date()),
          },
        },
      });
    } catch (_) {
      // TODO: показывать ошибку, если запрос не прошёл
      // см. комментарий в Local.jsx
    }
  };

  // TODO: переделать когда будут возвращать верхние кнопки сохранения
  const saveDraftData = async () => {
    const draft = draftsList.find((d) => d.id === draftId);
    const productAnswers = GetProductPackageData(
      state.carbonSources.product || draft.productAnswers
    );
    const packageAnswers = GetProductPackageData(
      state.carbonSources.package || draft.packageAnswers
    );
    const localAnswers = state.calculatorAnswers;
    const companyAnswers = GetCompanyData(state.company);

    if (draftId) {
      try {
        await updateDraft({
          variables: {
            input: {
              id: draftId,
              user_id: draft.userId,
              company_id: draft.companyId,
              company_name: draft.companyName,
              email: draft.email,
              legal_type_id: draft.legalTypeId,
              industrial_group_id: draft.industrialGroupId,
              company_date: formatDate(draft.establishedDate),
              registration_country_id: draft.countryId,
              zip_code: draft.zipCode,
              address1: draft.address1,
              address2: draft.address2,
              annual_turnover: draft.turnover,
              net_profit: draft.profit,
              year_taxes: draft.taxes,
              number_unique_products: draft.uniqProduct,
              annual_productivity_each_position: draft.productivity,
              employees_number: draft.employees,
              period_report_date: formatDate(draft.reportDate.getFullYear()),
              type: calculatorName,
              product_answers: JSON.stringify(productAnswers),
              package_answers: JSON.stringify(packageAnswers),
              answers: JSON.stringify(localAnswers),
              company_answers: JSON.stringify(companyAnswers),
              last_position: JSON.stringify(draft.lastPosition),
              state_graphics: JSON.stringify(chartData),
            },
          },
        });
      } catch (_) {
        // TODO: показывать ошибку, если запрос не прошёл
        // см. комментарий в Local.jsx
      }
    }
  };

  return (
    <ApiMethodContext.Provider
      value={{
        saveDraftData,
        completeDraft,
        saveNewCompanyData,
      }}
    >
      {children}
    </ApiMethodContext.Provider>
  );
}

ApiMethodService.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiMethodService;
