import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TodayIcon from "@mui/icons-material/Today";

const ERRORS = {
  REQUIRED: "required",
  RANGEOUT: "rangeout",
  INVALID: "invalid",
};

const ERROR_TEXT = {
  [ERRORS.REQUIRED]: "This is a required field. Please fill in this field.",
  [ERRORS.RANGEOUT]: "The date is out of range.",
  [ERRORS.INVALID]: "The input date is not valid.",
};

const MuiDatePicker = ({
  label,
  name,
  value,
  className,
  onChange,
  minDate,
  maxDate,
  onlyYear,
  disabled,
  required,
  type,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [timeoutID, setTimeoutID] = useState(null);
  const [helperText, setHelperText] = useState(null);

  const getError = () => {
    if (required && !inputValue) {
      return ERRORS.REQUIRED;
    }
    if (inputValue && inputValue.toString() === "Invalid Date") {
      return ERRORS.INVALID;
    }
    if (
      inputValue &&
      ((maxDate && maxDate < inputValue) || (minDate && minDate > inputValue))
    ) {
      return ERRORS.RANGEOUT;
    }
    return null;
  };

  useEffect(() => setInputValue(value), []);
  useEffect(
    () => setHelperText(ERROR_TEXT[getError()] ?? ""),
    [inputValue, required, minDate, maxDate, value]
  );

  const DebouncedChange = (newValue) => {
    setInputValue(newValue);
    onChange({
      target: {
        name,
        value: getError() ? null : newValue,
      },
    });
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const newTimeoutId = setTimeout(() => {
      const val = e.target.value;
      DebouncedChange(val);
    }, 300);
    setTimeoutID(newTimeoutId);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        name={name}
        disabled={disabled}
        components={{
          OpenPickerIcon: TodayIcon,
        }}
        views={onlyYear ? ["year"] : undefined}
        minDate={minDate}
        maxDate={maxDate}
        inputFormat={onlyYear ? "yyyy" : "dd.MM.yyyy"}
        mask={onlyYear ? "____" : "__.__.____"}
        value={inputValue}
        onChange={(newValue) => {
          handleChange({
            target: { name, value: newValue },
          });
        }}
        renderInput={(params) => (
          <TextField
            type={type}
            label={label}
            readOnly
            helperText={helperText}
            error={helperText}
            className={className}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};
MuiDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onlyYear: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  required: PropTypes.bool,
};

MuiDatePicker.defaultProps = {
  value: null,
  label: "",
  className: "",
  onChange: () => {},
  minDate: null,
  maxDate: null,
  onlyYear: false,
  disabled: false,
};

export default MuiDatePicker;
