import PropTypes from "prop-types";
import clsx from "clsx";
import React from "react";
import styles from "./index.module.scss";
import MuiSelect from "../../../components/muiSelect/MuiSelect";

const DropdownQuestion = ({ name, text, answer, setAnswer, variants }) => (
  <>
    <p>{text}</p>
    <MuiSelect
      className={clsx(styles.questionInput, styles.normal)}
      name={name}
      value={answer || ""}
      onChange={setAnswer}
      items={variants}
    />
  </>
);

DropdownQuestion.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  answer: PropTypes.string,
  setAnswer: PropTypes.func,
  variants: PropTypes.array,
};

export default DropdownQuestion;
