import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import { ReactComponent as NotFoundSvg } from "../../assets/icons/notFound.svg";

import styles from "./NotFound.module.scss";

const NotFound = () => {
  const navigate = useNavigate();

  const goToPreviousPage = () => {
    navigate(-1);
  };

  return (
    <div className={styles.notFound}>
      <NotFoundSvg className={styles.notFoundSvg} />

      <div className={styles.errorText}>
        <span className={styles.errorNumber}>404</span>
        <span className={styles.error}>error</span>
        <span className={styles.explanation}>This page doesn`t exist</span>

        <Button
          className={styles.backBtn}
          variant="contained"
          color="dark"
          onClick={goToPreviousPage}
        >
          BACK
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
