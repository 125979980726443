import { createTheme } from "@mui/material/styles";

const spTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  typography: { fontFamily: ["Montserrat", "Arial", "sans-serif"].join(",") },
  palette: {
    primary: {
      main: "#18397A",
      light: "#1F4A9D",
      dark: "#143471",
      lighter: "#8C9DBD",
      contrastText: "#fff",
    },
    dark: {
      main: "#212121",
      contrastText: "#fff",
    },
    lightBlue: { main: "#01A0C6" },
    common: {
      black: "#212121",
      secondary: "#757575",
      disabled: "#ccc",
      white: "#fff",
    },
    text: { primary: "#212121", secondary: "#757575", disabled: "#ccc" },
  },
});

spTheme.typography = {
  ...spTheme.typography,
  body1: {
    fontSize: "14px",
    lineHeight: "20px",
    [spTheme.breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  button: {
    fontSize: "12px",
    lineHeight: "40px",
    fontWeight: 700,
    textTransform: "uppercase",
    [spTheme.breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "48px",
    },
  },
};

spTheme.components = {
  ...spTheme.components,
  MuiTypography: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.variant === "placeholder" && {
          color: spTheme.palette.text.secondary,
        }),
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: () => ({
        maxWidth: "100vw",
        overflowX: "auto",
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        padding: "0 20px",
        boxShadow: "unset",
        whiteSpace: "nowrap",
        ".MuiTouchRipple-root": {
          display: "none",
        },
        "&:hover": { boxShadow: "unset" },
        ...(ownerState.form === "rounded"
          ? { borderRadius: "8px" }
          : undefined),
        ...(ownerState.variant === "text" && {
          "&:hover": {
            span: {
              svg: {
                path: { fill: spTheme.palette[ownerState.color].light },
              },
            },
            backgroundColor: "transparent",
            color: spTheme.palette[ownerState.color].light,
          },
          "&:active": {
            span: {
              svg: {
                path: { fill: spTheme.palette[ownerState.color].dark },
              },
            },
            color: spTheme.palette[ownerState.color].dark,
          },
          "&:disabled": {
            color: spTheme.palette[ownerState.color].lighter,
          },
        }),
        ...(ownerState.variant === "contained" && {
          border: `1px solid ${spTheme.palette[ownerState.color].main}`,
          "&:hover": {
            backgroundColor: spTheme.palette[ownerState.color].light,
            boxShadow: "unset",
          },
          "&:active": {
            backgroundColor: spTheme.palette[ownerState.color].dark,
            boxShadow: "unset",
          },
          "&:disabled": {
            backgroundColor: spTheme.palette[ownerState.color].lighter,
            borderColor: spTheme.palette[ownerState.color].lighter,
            color: spTheme.palette[ownerState.color].contrastText,
          },
        }),
        ...(ownerState.variant === "outlined" && {
          borderColor: spTheme.palette[ownerState.color].main,
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: spTheme.palette[ownerState.color].light,
            color: spTheme.palette[ownerState.color].light,
          },
          "&:active": {
            borderColor: spTheme.palette[ownerState.color].dark,
            color: spTheme.palette[ownerState.color].dark,
          },
          "&:disabled": {
            borderColor: spTheme.palette[ownerState.color].lighter,
            color: spTheme.palette[ownerState.color].lighter,
          },
        }),
        ...(ownerState.size === "small" && {
          padding: 0,
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          [spTheme.breakpoints.up("md")]: { lineHeight: "32px" },
        }),
        ...(ownerState.size === "wide" && {
          padding: "14px 40px",
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "20px",
          [spTheme.breakpoints.up("md")]: { lineHeight: "24px" },
        }),
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        minHeight: "50px",
        borderRadius: "16px",
        background: spTheme.palette.common.white,
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: spTheme.palette.primary.main,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${spTheme.palette.primary.main}`,
        },
        ".MuiOutlinedInput-input": {
          padding: "0 20px",
          borderRadius: "16px",
          "&::placeholder": {
            color: spTheme.palette.text.secondary,
            opacity: 1,
          },
        },
        fieldset: {
          border: `1px solid ${spTheme.palette.common.disabled}`,
          borderRadius: "16px",
        },
        [spTheme.breakpoints.up("md")]: {
          ".MuiOutlinedInput-input": { padding: "12px 20px" },
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        "&.fullWidth": { ".MuiOutlinedInput-root": { width: "100%" } },
        "&.thin": {
          ".MuiOutlinedInput-root": {
            minHeight: "40px",
            maxHeight: "40px",
            fieldset: { borderRadius: "12px" },
            ".MuiOutlinedInput-input": { padding: "8px 20px" },
          },
          ".MuiInputLabel-root": {
            transform: "translate(20px, 10px) scale(1)",
            "&.Mui-focused, &.MuiFormLabel-filled": {
              transform: "translate(14px, -9px) scale(0.75)",
            },
            [spTheme.breakpoints.up("md")]: {
              transform: "translate(20px, 8px) scale(1)",
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
            },
          },
          "&.withStartIcon, &.withEndIcon": {
            ".MuiOutlinedInput-root": {
              fieldset: { legend: { span: { display: "none" } } },
              "&.Mui-focused, &.filled": {
                fieldset: { legend: { span: { display: "inline" } } },
                svg: { fill: spTheme.palette.primary.main },
              },
            },
            ".MuiInputLabel-root": {
              transform: "translate(45px, 10px) scale(1)",
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
              [spTheme.breakpoints.up("md")]: {
                transform: "translate(45px, 8px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
            },
          },
          "&.withEndIcon": {
            ".MuiInputLabel-root": {
              transform: "translate(20px, 10px) scale(1)",

              [spTheme.breakpoints.up("md")]: {
                transform: "translate(20px, 8px) scale(1)",
              },
            },
          },
        },
        ".MuiInputLabel-root": {
          transform: "translate(20px, 14px) scale(1)",
          "&.Mui-focused": { transform: "translate(14px, -9px) scale(0.75)" },
        },
        ".MuiFormLabel-filled": {
          transform: "translate(14px, -9px) scale(0.75)",
        },
        ...(ownerState.type === "select" && {
          ".MuiOutlinedInput-root": {
            ".MuiOutlinedInput-input": { paddingRight: "32px !important" },
            "&.Mui-focused": {
              ".MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${spTheme.palette.common.disabled}`,
              },
              ".MuiSelect-select .MuiTypography-root": {
                color: spTheme.palette.text.primary,
                fontWeight: 600,
              },
            },
          },
        }),
      }),
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        ".MuiList-root": {
          padding: "6px 0",
        },
        ".MuiMenu-paper": {
          // boxShadow: "unset",
          border: `1px solid ${spTheme.palette.common.disabled}`,
          borderTop: "none",
          borderRadius: "16px",
          marginTop: "-49px",
          maxHeight: "50%",
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: "18px 40px 18px 40px",
      },
    },
  },
  MuiCheckbox: {
    variants: [
      {
        props: { variant: "unlighted" },
        style: {
          "&.Mui-checked": {
            svg: { fill: spTheme.palette.common.black },
            "&, & + .MuiFormControlLabel-label": {
              color: spTheme.palette.text.primary,
              fontSize: "14px",
              lineHeight: "20px",
              [spTheme.breakpoints.up("md")]: {
                fontSize: "16px",
              },
            },
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        "&.Mui-checked": {
          svg: {
            fill: spTheme.palette.primary.dark,
          },
          "&, & + .MuiFormControlLabel-label": {
            color: spTheme.palette.primary.dark,
          },
        },
        "&.Mui-disabled": {
          svg: { fill: spTheme.palette.common.disabled },
          "&, & + .MuiFormControlLabel-label": {
            color: spTheme.palette.text.disabled,
            svg: { path: { fill: spTheme.palette.common.disabled } },
          },
        },
        svg: { fill: spTheme.palette.common.black },
        "&, & + .MuiFormControlLabel-label": {
          color: spTheme.palette.text.primary,
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: "unset",
        border: "none",
        background: "transparent",
        margin: 0,

        "&:first-of-type": {
          paddingTop: "20px",
        },
        "&:not(:last-of-type)": {
          paddingBottom: "8px",
        },
        "&:last-of-type": {
          paddingBottom: "24px",
        },
        "&::before": { background: "none" },
        "& .MuiAccordionSummary-root": {
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: spTheme.palette.text.primary,
          padding: 0,
          minHeight: "auto",
          svg: { fill: spTheme.palette.text.primary },
          "& .MuiAccordionSummary-content": { margin: 0 },
          "&.Mui-expanded": {
            fontWeight: 700,
            color: spTheme.palette.primary.main,
            minHeight: "auto",
          },
        },
        "& .MuiAccordionDetails-root": {
          fontSize: "14px",
          lineHeight: "24px",
          maxHeight: "100px",
          padding: "0",
          marginTop: "10px",
          overflow: "auto",
          "& p": { margin: 0 },
        },
        "&.Mui-expanded": { margin: 0 },
        [spTheme.breakpoints.up("md")]: {
          "& .MuiAccordionDetails-root": {
            padding: "0 20px 0 0",
          },
        },
        [spTheme.breakpoints.up("lg")]: {
          "& .MuiAccordionSummary-root": {
            fontSize: "20px",
            lineHeight: "32px",
          },
          "& .MuiAccordionDetails-root": {
            fontSize: "16px",
            maxHeight: "120px",
          },
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        width: "44px",
        height: "44px",
        [spTheme.breakpoints.up("md")]: { width: "52px", height: "52px" },
      },
    },
  },
};

export default spTheme;
