import {
  SET_USER_DATA,
  SET_USER_COMPANIES,
  SET_COUNTRIES,
  SET_INDUSTRIAL_GROUPS,
  SET_SUBINDUSTRIES,
  SET_LEGAL_TYPES,
  SET_DRAFT_ID,
  SET_DRAFTS,
  ADD_USER_COMPANY,
  SET_NEW_COMPANY_DATA,
} from "../types";

const userReducer = (state, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case SET_USER_COMPANIES:
      return {
        ...state,
        companies: { ...action.payload },
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: { ...action.payload },
      };
    case SET_INDUSTRIAL_GROUPS:
      return {
        ...state,
        industrialGroups: { ...action.payload },
      };
    case SET_SUBINDUSTRIES:
      return {
        ...state,
        subIndustries: { ...action.payload },
      };
    case SET_LEGAL_TYPES:
      return {
        ...state,
        legalTypes: { ...action.payload },
      };
    case SET_DRAFT_ID:
      return {
        ...state,
        draftId: action.payload,
      };
    case SET_DRAFTS:
      return {
        ...state,
        draftsList: action.payload,
      };
    case ADD_USER_COMPANY:
      return {
        ...state,
        companies: {
          ...state.companies,
          [action.payload.id]: {
            ...action.payload,
            userId: state.user.id,
          },
        },
        user: {
          ...state.user,
          companyId: action.payload.id,
        },
      };
    case SET_NEW_COMPANY_DATA:
      return {
        ...state,
        companies: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
