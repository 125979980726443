import React, { useState } from "react";

import Modal from "../CalcModal/CalcModal";

import styles from "./SelfAssessment.module.scss";

import { ReactComponent as HelpSvg } from "../../assets/icons/help.svg";
import { SelfAssessmentDisclaimerText } from "../../constants";

const SelfAssessment = () => {
  const [isDisclaimerOpened, setIsDisclaimerOpened] = useState(false);

  const openDisclaimer = () => {
    setIsDisclaimerOpened(true);
  };
  const closeDisclaimer = () => {
    setIsDisclaimerOpened(false);
  };

  return (
    <>
      <div className={styles.selfAssessment}>
        <button onClick={openDisclaimer}>
          <HelpSvg></HelpSvg>
        </button>
        <span onClick={openDisclaimer}>You are running a self-assessment</span>
      </div>
      <Modal
        opened={isDisclaimerOpened}
        closeModalFunc={closeDisclaimer}
        headerContent="Disclaimer"
        okBtnText="Ok"
        needAgreement={false}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: SelfAssessmentDisclaimerText.base,
          }}
        />
      </Modal>
    </>
  );
};

export default SelfAssessment;
