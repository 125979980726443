import React from "react";
import Cookies from "js-cookie";
import { DisconnectHandler } from "tt-disconnect";
import { useLocation } from "react-router";
import { useLazyQuery, useMutation } from "@apollo/client";
// import { DisconnectHandlerBase } from ".";
import { GET_CURRENT_USER, LOGOUT_USER } from "../../api";
import { logoutUserHandler } from "../../utils";

const DisconnectHandlerWrapper = () => {
  const userId = Cookies.get("userId");
  const domainValue = process.env.REACT_APP_DOMAIN;
  const location = useLocation();

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER);
  const [logoutUser] = useMutation(LOGOUT_USER);

  const logOut = () => {
    logoutUserHandler(logoutUser);
  };

  const getUser = async () => {
    const user = await getCurrentUser();
    return user;
  };

  return (
    <DisconnectHandler
      getUser={getUser}
      location={location}
      userId={userId}
      logoutHandler={logOut}
      logoutDelay={1200000}
      domainValue={domainValue}
    />
  );
};

export default DisconnectHandlerWrapper;
