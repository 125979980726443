import clsx from "clsx";
import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Grid, Button } from "@mui/material";
import {
  CalculatorsContext,
  UserContext,
  ApiMethodContext,
} from "../../../context";
import { UPDATE_DRAFT, GET_GLOBAL_INPUT_TYPES_DATA } from "../../../api";

import Form from "../../../components/form/Form";
import Pagination from "../../../components/pagination/Pagination";
import DropdownQuestion from "./DropdownQuestion";
import NumberQuestion from "./NumberQuestion";

import styles from "./index.module.scss";
import QuestionButton from "../../../components/questionButton/QuestionButton";
import MuiCheckBox from "../../../components/muiCheckBox/MuiCheckBox";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
// import { CalculatorsListData } from "../../../constants";
import FileUploader from "../../../components/fileUploader/FileUploader";
import StringQuestion from "./StringQuestion";
import CalculationCost from "../../../components/calculationCost/CalculationCost";

// TODO: парсить dimention, category  и проверять, если не проходит проверку - отправлять на рут
const Global = ({
  setQuestionIndex,
  setQuestionCount,
  setAccordionItems,
  calculatorContainerRef,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { dimUrl, catUrl, idxUrl } = useParams();

  const dimention = dimUrl - 1;
  const category = catUrl - 1;
  const index = idxUrl - 1;

  const [updateDraft] = useMutation(UPDATE_DRAFT);
  const [getGlobalInputTypesData, { data: inputTypesData }] = useLazyQuery(
    GET_GLOBAL_INPUT_TYPES_DATA
  );
  const { saveDraftData, completeDraft } = useContext(ApiMethodContext);
  const { draftId, draftsList, changeDraftsList, setDraftId, subIndustries } =
    useContext(UserContext);

  const {
    chartData,
    globalQuestions,
    changeGlobalCalculatorAnswer,
    setDraftData,
    changeGlobalInputTypesData,
    selectedCalculators,
    calculatorName,
    globalInputTypes,
  } = useContext(CalculatorsContext);

  const [isInfoOn, setIsInfoOn] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [globalAnswers, setGlobalAnswers] = useState({});
  const [qId, setQId] = useState("");

  useEffect(() => {
    if (!calculatorContainerRef) return;
    calculatorContainerRef.current.scrollTo(0, 0);
  }, [dimention, category, index]);

  useEffect(() => {
    if (!questions || !globalAnswers) return;
    const newQId = questions[index]?.id;
    setQId(newQId);
    setIsInfoOn(!!globalAnswers[newQId]);
  }, [questions, globalAnswers]);

  useEffect(() => {
    if (!draftId) {
      const sessionDraftId = window.sessionStorage.getItem("SELECTED_DRAFT_ID");
      if (sessionDraftId) setDraftId(sessionDraftId);
      else if (selectedCalculators && calculatorName === "global")
        navigate("/solo_start");
      else {
        navigate("/drafts");
      }
    }
  }, []);

  useEffect(() => {
    if (!draftId || !draftsList || draftsList.length === 0 || !subIndustries)
      return;

    const draft = draftsList.find((d) => d.id === draftId);
    const { subIndustryId } = draft;
    const { code } = subIndustries[subIndustryId];
    getGlobalInputTypesData({
      variables: { input: code },
    });
  }, [draftId, draftsList, subIndustries]);

  useEffect(() => {
    if (inputTypesData) {
      changeGlobalInputTypesData(inputTypesData.getGlobalVariants);
    }
  }, [inputTypesData]);

  useEffect(() => {
    if (!globalQuestions) return;
    const questionsCategory = globalQuestions[dimention].categories[category];
    const newQuestions = questionsCategory?.questions;
    setQuestions([...newQuestions]);
  }, [index, dimention, category, globalQuestions]);

  useEffect(() => {
    if (!questions || !questions[index] || !globalAnswers) return;
    setIsFilled(
      globalAnswers[qId] ||
        questions[index].subQuestions.some((el) => !!globalAnswers[el.code])
    );
  }, [globalAnswers, questions]);

  useEffect(() => {
    if (!draftId || !draftsList.length > 0 || !globalQuestions) return;
    const draft = draftsList.find((d) => d.id === draftId);
    setDraftData(draft);
  }, [draftId, draftsList, globalQuestions]);

  useEffect(() => {
    if (!draftId || !draftsList.length > 0) return;
    const draft = draftsList.find((d) => d.id === draftId);
    setGlobalAnswers(draft.globalAnswers);
  }, [draftId, draftsList]);

  useEffect(() => {
    if (!globalQuestions) return;
    const questionIndex = globalQuestions.reduce((dres, dim, didx) => {
      if (didx >= dimention) return dres;
      return (
        dres +
        dim.categories.reduce((res, cat, idx) => {
          if (idx > category) return res;
          if (idx < category) return res + cat.questions.length;
          return res + index + 1;
        }, 0)
      );
    }, 0);
    setQuestionIndex(questionIndex);
    const questionsLength = globalQuestions.reduce(
      (dres, dim) =>
        dres +
        dim.categories.reduce((res, cat) => res + cat.questions.length, 0),
      0
    );
    setQuestionCount(questionsLength);

    const accordionItems = globalQuestions.map((dim, idx) => {
      const item = {
        content: dim.description,
        expanded: idx === dimention,
        name: dim.name,
      };
      return item;
    });
    setAccordionItems(accordionItems);
  }, [index, globalQuestions, globalAnswers]);

  useEffect(() => {
    if (!globalAnswers || !globalQuestions) return;
    changeGlobalCalculatorAnswer({
      dimention,
      category,
      globalAnswers,
    });
  }, [globalAnswers]);

  const handleCheckBoxChange = (e) => {
    setIsInfoOn(e.target.checked);
    if (!e.target.checked) {
      delete globalAnswers[qId];
      setGlobalAnswers({ ...globalAnswers });
    }
  };

  const onChangeAnswer = (e) => {
    const { name, value } = e.target;
    if (value) {
      globalAnswers[name] = value;
    } else {
      delete globalAnswers[name];
    }
    setGlobalAnswers({ ...globalAnswers });
  };

  const onSaveAnswer = async () => {
    globalAnswers.lastQuestion = {
      dimention: dimention + 1,
      category: category + 1,
      index: index + 1,
    };
    if (draftId) {
      const newDraftList = draftsList.map((d) => {
        const newDraft = d.id === draftId ? { ...d, globalAnswers } : d;
        return newDraft;
      });
      // const draft = newDraftList.find((d) => d.id === draftId);
      changeDraftsList(newDraftList);
      // setGlobalAnswerFromDraft(draft.globalAnswers);
      try {
        await updateDraft({
          variables: {
            input: {
              id: draftId,
              global_answers: JSON.stringify(globalAnswers),
              last_position: JSON.stringify(pathname),
              state_graphics: JSON.stringify(chartData),
            },
          },
        });
      } catch (_) {
        // TODO: показывать ошибку, если запрос не прошёл
        // сейчас ошибки игнорируются и происходит переход дальше
        // ответы сохраняются локально и будут сохранены в следующих запросах
        // проблема будет только если все запросы вернут ошибки — прохождение не сохранится
      }
    }
  };

  const onBackClick = async () => {
    await onSaveAnswer();

    let newQuestionsIndex = index - 1;
    const oldCategory = category;
    const oldDimention = dimention;
    let newCategoryIndex = oldCategory;
    let newDimentionIndex = oldDimention;

    if (newQuestionsIndex < 0) {
      newCategoryIndex = oldCategory - 1;
      if (newCategoryIndex < 0) {
        newDimentionIndex = oldDimention - 1;
        newCategoryIndex =
          globalQuestions[newDimentionIndex] &&
          globalQuestions[newDimentionIndex].categories.length - 1;
      }
      if (newDimentionIndex < 1) {
        navigate("/calculators/global/1/1/1");
      }
      newQuestionsIndex =
        globalQuestions[newDimentionIndex].categories[newCategoryIndex]
          .questions.length - 1;
    }
    navigate(
      `/calculators/global/${newDimentionIndex + 1}/${newCategoryIndex + 1}/${
        newQuestionsIndex + 1
      }`
    );
  };

  const onBackToListOfIssues = () => {
    navigate(`/drafts`);
  };

  const getNewIndexes = () => {
    const res = {};
    res.newQuestionsIndex = index === questions.length - 1 ? 0 : index + 1;
    const oldCategory = category;
    const oldDimention = dimention;
    res.newCategoryIndex = oldCategory;
    res.newDimentionIndex = oldDimention;

    if (index === questions.length - 1) {
      res.newCategoryIndex =
        oldCategory === globalQuestions[oldDimention].categories.length - 1
          ? 0
          : oldCategory + 1;
      if (oldCategory === globalQuestions[oldDimention].categories.length - 1) {
        res.newDimentionIndex = oldDimention + 1;
      }
    }
    return res;
  };

  const onNextClick = async () => {
    await onSaveAnswer();

    const newIndexes = getNewIndexes();
    if (newIndexes.newDimentionIndex > globalQuestions.length - 1) {
      if (Object.keys(globalAnswers).length) {
        await completeDraft();
        navigate(`/buy_credits/${draftId}`);
      } else navigate("/drafts");
      return;
    }
    navigate(
      `/calculators/global/${newIndexes.newDimentionIndex + 1}/` +
        `${newIndexes.newCategoryIndex + 1}/${newIndexes.newQuestionsIndex + 1}`
    );
  };

  // Temporary all the inputs is textFields
  const isInputRender = (type) => type === "string";

  // const isInputRender = (type, code) =>
  //   !!globalInputTypes &&
  //   ((globalInputTypes[code] &&
  //     globalInputTypes[code].type &&
  //     globalInputTypes[code].type === type) ||
  //     (!globalInputTypes[code] && !["dropdown", "number"].includes(type)));

  const currentPages = questions?.map(({ id }) => id) ?? [];

  return (
    <>
      {draftId && questions[index] && globalAnswers && (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
          className={styles.formContainer}
        >
          <div>
            <div>
              <QuestionButton type={"label"} label="S&P Global CapitalIQ" />
              <QuestionButton
                isAnsvered={isFilled}
                type={"isAnsvered"}
                label={isFilled ? "Answered" : "Answer required"}
              />
              <QuestionButton
                action={onBackToListOfIssues}
                type={"redirect"}
                label={"Back to list of issues"}
              />
              <QuestionButton
                action={saveDraftData}
                type={"save"}
                label={"Save & complete later"}
              />
            </div>
            <div className={styles.formContainer}>
              <Form headerContent={questions[index].title}>
                <div className={styles.questionContainer}>
                  {questions[index].subQuestions.map((q) => (
                    <div key={q.code} className={styles.question}>
                      {isInputRender("string", q.code) && (
                        <StringQuestion
                          name={q.code}
                          text={q.text}
                          answer={globalAnswers[q.code]}
                          onChange={onChangeAnswer}
                        />
                      )}
                      {isInputRender("number", q.code) && (
                        <NumberQuestion
                          name={q.code}
                          text={q.text}
                          answer={globalAnswers[q.code]}
                          onChange={onChangeAnswer}
                        />
                      )}
                      {isInputRender("dropdown", q.code) && (
                        <DropdownQuestion
                          name={q.code}
                          text={q.text}
                          answer={globalAnswers[q.code]}
                          setAnswer={onChangeAnswer}
                          variants={globalInputTypes[q.code].variants}
                        />
                      )}
                    </div>
                  ))}
                </div>
                <div className={styles.questionFooter}>
                  <div
                    className={clsx(
                      styles.isNotAvailable,
                      !isInfoOn ? styles.partWidth : styles.fullWidth,
                      isInfoOn && styles.selected
                    )}
                  >
                    <MuiCheckBox
                      label={"This information is not available"}
                      className={styles.checkbox}
                      value={isInfoOn}
                      onChange={handleCheckBoxChange}
                      name="isNotAvailable"
                    />
                    {isInfoOn && (
                      <MuiTextField
                        className={styles.info}
                        name={qId}
                        label={"Information"}
                        value={globalAnswers[qId]}
                        onChange={onChangeAnswer}
                        required={false}
                        inputProps={{ maxLength: 255 }}
                      />
                    )}
                  </div>
                  <div>
                    {qId && (
                      <FileUploader questionCode={qId} disabled={!isFilled} />
                    )}
                  </div>
                </div>
              </Form>
              <CalculationCost />
            </div>
          </div>
          <div className={styles.navigation}>
            <Button variant="outlined" onClick={onBackClick}>
              BACK
            </Button>

            <Pagination
              activePage={currentPages.indexOf(questions[index].id) + 1}
              pagesCount={currentPages.length}
            />
            <Button variant="contained" onClick={onNextClick}>
              {getNewIndexes().newDimentionIndex > globalQuestions.length - 1
                ? "FINISH"
                : "NEXT"}
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
};

Global.propTypes = {
  setQuestionIndex: PropTypes.func,
  questionIndex: PropTypes.number,
  setQuestionCount: PropTypes.func,
  setAccordionItems: PropTypes.func,
  calculatorContainerRef: PropTypes.any,
};

Global.defaultProps = {
  setQuestionIndex: () => {},
  questionIndex: 0,
  setQuestionCount: () => {},
  setAccordionItems: () => {},
};

export default Global;
