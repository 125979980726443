export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_COMPANIES = "SET_USER_COMPANIES";
export const SET_NEW_COMPANY_DATA = "SET_NEW_COMPANY_DATA";
export const ADD_USER_COMPANY = "ADD_USER_COMPANY";

export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_INDUSTRIAL_GROUPS = "SET_INDUSTRIAL_GROUPS";
export const SET_SUBINDUSTRIES = "SET_SUBINDUSTRIES";
export const SET_LEGAL_TYPES = "SET_LEGAL_TYPES";

export const SET_DRAFT_ID = "SET_DRAFT_ID";
export const SET_DRAFTS = "SET_DRAFTS";

export const SET_CHART_DATA = "SET_CHART_DATA";
export const SET_QUESTIONS_DATA = "SET_QUESTIONS_DATA";
export const SET_PRODUCT_MATERIALS_DATA = "SET_PRODUCT_MATERIALS_DATA";
export const SET_PACKAGE_MATERIALS_DATA = "SET_PACKAGE_MATERIALS_DATA";
export const SET_COMPANY_QUESTIONS_DATA = "SET_COMPANY_QUESTIONS_DATA";
export const SET_GLOBAL_QUESTIONS_DATA = "SET_GLOBAL_QUESTIONS_DATA";
export const SET_GLOBAL_INPUT_TYPES = "SET_GLOBAL_INPUT_TYPES";
export const SET_CARBON_STRUCTURE = "SET_CARBON_STRUCTURE";
export const SET_CALCULATORS_LIST = "SET_CALCULATORS_LIST";
export const SET_CARBON_SOURCES = "SET_CARBON_SOURCES";
export const SET_COMPANY_DATA = "SET_COMPANY_DATA";
export const SET_CALCULATOR_DATA = "SET_CALCULATOR_DATA";
export const SET_CALCULATOR_NAME = "SET_CALCULATOR_NAME";
export const SET_SCORE = "SET_SCORE";
export const SET_LAST_VISITED_INDEX = "SET_LAST_VISITED_INDEX";
export const SET_CALCULATOR_COST = "SET_CALCULATOR_COST";
export const SET_CALCULATOR_ANSWER = "SET_CALCULATOR_ANSWER";
export const SET_GLOBAL_CALCULATOR_ANSWERS = "SET_GLOBAL_CALCULATOR_ANSWERS";
export const SET_APP_LOADING = "SET_APP_LOADING";
export const SET_APP_LOADED = "SET_APP_LOADED";
export const SET_CURRENT_CALC_SECTION = "SET_CURRENT_CALC_SECTION";
export const SET_ERROR_ALERT = "SET_ERROR_ALERT";
