import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import AppRouter from "./AppRouter";
import About from "./views/about/About";
import NotFound from "./views/notFound/NotFound";
import OffsetCertificates from "./views/offsetCertificates/OffsetCertificates";
import { GET_CURRENT_USER } from "./api";
import { UserContext } from "./context";
import ExternalLink from "./components/externalLink/ExternalLink";
import Logout from "./views/Logout";

const App = () => {
  const [getUser, { data: currentUserData }] = useLazyQuery(GET_CURRENT_USER);
  const { changeUserData } = useContext(UserContext);

  useEffect(() => {
    getUser().then((res) => {
      if (res?.data?.currentUser?.id) {
        changeUserData(res.data.currentUser);
      } else {
        window.location = process.env.REACT_APP_SSO_SIGN_IN;
      }
    });
  }, []);

  useEffect(() => {
    if (currentUserData?.currentUser) {
      changeUserData(currentUserData.currentUser);
    }
  }, [currentUserData]);

  return (
    <Routes>
      <Route exact path="/login" element={<ExternalLink />} />
      <Route exact path="/logout" element={<Logout />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/offset-credits" element={<OffsetCertificates />} />

      <Route exact path="/drafts" element={<ExternalLink />} />
      <Route exact path="/profile/:param" element={<ExternalLink />} />
      <Route exact path="/completed" element={<ExternalLink />} />
      <Route exact path="/buy_credits/:param" element={<ExternalLink />} />
      <Route exact path="/newassessment" element={<ExternalLink />} />
      <Route exact path="/carbon_credits" element={<ExternalLink />} />

      <Route exact path="/" element={<About />} />
      <Route exact path="/*" element={<AppRouter />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
