import clsx from "clsx";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Modal, Button } from "tt-ui-kit";
import CalcModal from "../../components/CalcModal/CalcModal";
import {
  GET_DRAFTS_BY_COMPANY,
  GET_DRAFT_BY_ID,
  REPLACE_DRAFT,
  UPDATE_DRAFT,
} from "../../api";
import {
  UserContext,
  ApiMethodContext,
  CalculatorsContext,
} from "../../context";
import styles from "./Registration.module.scss";
/* eslint-disable-next-line import/no-named-default */
import FileUploader from "../../components/fileUploader/FileUploader";
import Form from "../../components/form/Form";
import SecondStep from "./SecondStep";
import RegistrationProject from "./RegistrationProject.jsx";
import RegistrationCarbon from "./RegistrationCarbon.jsx";
import RegistrationCustom from "./RegistrationCustom.jsx";
import { CALC_TYPES, CalculatorsListData } from "../../constants";
import { formatDate } from "../../utils";

const Registration = () => {
  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(0);
  const [initialDraft, setInitialDraft] = useState(null);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [isFileModalOpened, setIsFileModalOpened] = useState(false);
  const [isDisclaimerOpened, setIsDisclaimerOpened] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [company, setCompany] = useState(null);
  const [requiredFields, setRequiredFields] = useState([]);
  const [validity, setValidity] = useState({});

  const [draftIdForRemove, setDraftIdForRemove] = useState(null);
  const [choosedAction, setChoosedAction] = useState(null);
  const [years, setYears] = useState([]);
  const [reportYear, setReportYear] = useState(null);

  const { selectedCalculators, calculatorName, setDraftData } =
    useContext(CalculatorsContext);
  const {
    setDraftsList,
    setDraftId,
    draftId,
    draftsList,
    changeDraftsList,
    companies,
    legalTypes,
    industrialGroups,
    prepareDraft,
  } = useContext(UserContext);
  const { getQuestionsFile } = useContext(ApiMethodContext);

  const navigate = useNavigate();

  const { data: draftData } = useQuery(GET_DRAFT_BY_ID, {
    variables: { id: draftId },
  });

  const [getCompanyDrafts, { data: companyDraftsData }] = useLazyQuery(
    GET_DRAFTS_BY_COMPANY
  );

  const [updateDraft] = useMutation(UPDATE_DRAFT);
  const [replaceDraft, { data: replaceDraftData }] = useMutation(REPLACE_DRAFT);

  useEffect(() => {
    if (!replaceDraftData || !replaceDraftData.replaceEsgDraft) return;
    const newDraft = prepareDraft(replaceDraftData.replaceEsgDraft);
    setSelectedDraft({ ...newDraft });
  }, [draftData]);

  useEffect(() => {
    if (!draftData || !draftData.draftById) return;
    const newDraft = prepareDraft(draftData.draftById);
    setInitialDraft({ ...newDraft });
    setSelectedDraft({ ...newDraft });
    if (newDraft.reportDate) setReportYear(newDraft.reportDate.getFullYear());
  }, [draftData]);

  useEffect(() => {
    if (!initialDraft || !initialDraft.companyId || !initialDraft.type) return;
    getCompanyDrafts({
      variables: { id: initialDraft.companyId, type: initialDraft.type },
    });
  }, [initialDraft]);

  useEffect(() => {
    if (!companies || !initialDraft || !legalTypes || !industrialGroups) return;
    setCompany(companies[initialDraft.companyId]);
  }, [initialDraft, companies, legalTypes, industrialGroups]);

  useEffect(() => {
    if (companyDraftsData) {
      const list = companyDraftsData.draftsByCompanyAndType;
      setDraftsList(list);
    }
  }, [companyDraftsData]);

  useEffect(() => {
    if (!draftsList || !draftsList.length) return;
    const newYears = draftsList.reduce(
      (res, d) => (d.reportDate ? res.add(d.reportDate.getFullYear()) : res),
      new Set()
    );
    setYears([...newYears]);
  }, [draftsList]);

  useEffect(() => {
    setIsNavigate(false);
  }, []);

  const numberFilter = /^(\d+\.)?\d+$/;

  useEffect(() => {
    if (!calculatorName) return;
    setRequiredFields({
      ...CalculatorsListData[calculatorName].requiredFields,
    });
  }, [calculatorName]);

  useEffect(() => {
    if (!requiredFields) return;
    setValidity({
      value: 0,
      total: Object.keys(requiredFields).reduce((sum, key) => {
        const fieldsCount =
          key === "reportYear" && requiredFields.reportYear
            ? 1
            : requiredFields[key].length;
        return sum + fieldsCount;
      }, 0),
    });
  }, [requiredFields]);

  const validatedFields = () => {
    if (!selectedDraft) return 0;

    let totalValidated = 0;
    totalValidated += requiredFields.numberFields
      ? requiredFields.numberFields.filter((n) =>
          numberFilter.test(selectedDraft[n])
        ).length
      : 0;
    totalValidated += requiredFields.stringFields
      ? requiredFields.stringFields.filter((n) => !!selectedDraft[n]).length
      : 0;
    totalValidated += requiredFields.reportYear && reportYear ? 1 : 0;
    totalValidated += requiredFields.dateFields
      ? requiredFields.dateFields.filter(
          (n) => !Number.isNaN(Date.parse(selectedDraft[n]))
        ).length
      : 0;
    return totalValidated;
  };

  useEffect(() => {
    setValidity((value) => ({
      value: validatedFields(),
      total: value.total,
    }));
  }, [selectedDraft, reportYear]);

  useEffect(() => {
    /* For the “Project” calculator, you must fill in both dates or neither */
    const newCheck =
      validity.value === validity.total &&
      (calculatorName !== "project" ||
        (calculatorName === "project" &&
          !!selectedDraft &&
          !!selectedDraft.reportDate === !!selectedDraft.reportDateEnd));

    setValidated(newCheck);

    const progressValue = validity.value / validity.total;
    setProgress(
      Number.isNaN(progressValue) || progressValue === 0
        ? "1px"
        : `${progressValue * 100}%`
    );
  }, [validity]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setSelectedDraft((newDraft) => ({
      ...newDraft,
      [name]: value,
    }));
  };

  const onChangeDate = (e) => {
    const newValue = e?.target?.value;
    setDraftIdForRemove(null);
    setReportYear(newValue ? newValue.getFullYear() : null);
  };

  const warningModalOpen = () => {
    setIsWarningModalOpen(true);
  };

  useEffect(() => {
    if (!reportYear) return;
    const exist = draftsList.find(
      (d) => d.reportDate && d.reportDate.getFullYear() === reportYear
    );
    if (exist && initialDraft.id === exist.id) return;
    const newDraft = {
      ...initialDraft,
      turnover: selectedDraft.turnover,
      profit: selectedDraft.profit,
      taxes: selectedDraft.taxes,
      uniqProduct: selectedDraft.uniqProduct,
      productivity: selectedDraft.productivity,
      employees: selectedDraft.employees,
    };
    setSelectedDraft({
      ...(exist ?? newDraft),
      reportDate: new Date(reportYear, 0, 1),
    });
    if (exist) {
      warningModalOpen();
    }
  }, [reportYear]);

  const closeFileModal = () => {
    setIsFileModalOpened(false);
  };

  const openFileModal = () => {
    getQuestionsFile({
      questionCode: "registration",
    });
    setIsFileModalOpened(true);
  };

  const goToNextPage = () => {
    const newDraftList = draftsList.map((d) =>
      d.id === selectedDraft.id ? { ...selectedDraft } : { ...d }
    );
    setDraftData(selectedDraft);
    setDraftId(selectedDraft.id);
    changeDraftsList(newDraftList);
    setIsNavigate(true);
  };

  useEffect(() => {
    if (!choosedAction) return;
    if (choosedAction === "load") {
      if (selectedDraft.lastPosition) {
        goToNextPage();
      }
    }
    if (choosedAction === "new") {
      setDraftIdForRemove(selectedDraft.id);
      setSelectedDraft({
        ...initialDraft,
        reportDate: new Date(reportYear, 0, 1),
      });
    }
    setIsWarningModalOpen(false);
    setChoosedAction(null);
  }, [choosedAction]);

  const resetChoosing = () => {
    setChoosedAction(null);
    setReportYear(null);
    setSelectedDraft({
      ...initialDraft,
      turnover: selectedDraft.turnover,
      profit: selectedDraft.profit,
      taxes: selectedDraft.taxes,
      uniqProduct: selectedDraft.uniqProduct,
      productivity: selectedDraft.productivity,
      employees: selectedDraft.employees,
    });
    setIsWarningModalOpen(false);
  };

  const saveDraft = async () => {
    const input = {
      id: selectedDraft.id,
      user_id: selectedDraft.userId,
      annual_turnover: selectedDraft.turnover,
      net_profit: selectedDraft.profit,
      year_taxes: selectedDraft.taxes,
      number_unique_products: selectedDraft.uniqProduct,
      annual_productivity_each_position: selectedDraft.productivity,
      employees_number: selectedDraft.employees,
      period_report_date:
        selectedDraft.type === "project"
          ? formatDate(selectedDraft.reportDate)
          : formatDate(selectedDraft.reportDate.getFullYear()),
      period_report_date_end:
        selectedDraft.type === "project"
          ? formatDate(selectedDraft.reportDateEnd)
          : "",
      type: selectedDraft.type,
    };

    if (draftIdForRemove) {
      await replaceDraft({
        variables: {
          old_id: draftIdForRemove,
          input: { ...input },
        },
      });
    } else {
      await updateDraft({
        variables: {
          input: { ...input },
        },
      });
    }
  };

  const startNavigation = async (url) => {
    await saveDraft();
    navigate(url);
  };

  useEffect(() => {
    if (!isNavigate) return;
    const url = selectedDraft.lastPosition
      ? selectedDraft.lastPosition
      : selectedCalculators[0].url;
    startNavigation(url);
  }, [isNavigate]);

  return (
    <>
      {initialDraft && selectedDraft && (
        <Modal
          open={isWarningModalOpen}
          onClose={() => setIsWarningModalOpen(false)}
          title="Start new assessment"
          closeOnlyByControls
        >
          <div className={styles.text}>
            {`You are about to start a new "${initialDraft.name}" assessment.
            Please note that all data from the last saved draft will be lost,
            and the "${selectedDraft.name}" will disappear from the list of all drafts.`}
          </div>
          <div className={clsx(styles.buttonBlock, styles.alertBB)}>
            <Button type="default" onClick={() => resetChoosing()}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => setChoosedAction("load")}>
              Load previous
            </Button>
            <Button type="primary" onClick={() => setChoosedAction("new")}>
              Replace with new
            </Button>
          </div>
        </Modal>
      )}
      <CalcModal
        opened={isFileModalOpened}
        closeModalFunc={closeFileModal}
        headerContent="Upload your confirmation docs"
        okBtnText="Ok"
        needAgreement={false}
        backBtnText="Back"
        backFunc={closeFileModal}
      >
        <FileUploader questionCode={"registration"} />
      </CalcModal>
      <CalcModal
        opened={isDisclaimerOpened}
        closeModalFunc={() => setIsDisclaimerOpened(false)}
        headerContent="Financial data"
        okBtnText="OK"
      >
        All this data is mandatory. Please fill out required fields.
      </CalcModal>
      <div className={styles.profileContainer}>
        <div className={styles.profile}>
          <div
            className={styles.progressBar}
            style={{
              background: `linear-gradient(to right, #01A0C6 ${progress}, transparent ${progress}, transparent 100%)`,
            }}
          />
          {selectedDraft && (
            <Form headerContent="Registration">
              {company && (
                <div className={styles.infoBlock}>
                  <div>
                    <div className={styles.info}>Company name</div>
                    <div className={styles.text}>{company.companyName}</div>
                  </div>
                  <div>
                    <div className={styles.info}>Address</div>
                    <div className={styles.text}>{company.address1}</div>
                  </div>
                  <div>
                    <div className={styles.info}>Company legal type</div>
                    <div className={styles.text}>
                      {legalTypes[company.legalTypeId].name}
                    </div>
                  </div>
                  <div>
                    <div className={styles.info}>Industrial sector</div>
                    <div className={styles.text}>
                      {industrialGroups[company.industrialGroupId].name}
                    </div>
                  </div>
                  <div>
                    <div className={styles.info}>Established date</div>
                    <div className={styles.text}>
                      {company.establishedDate ?? "no date"}
                    </div>
                  </div>
                </div>
              )}
              {(calculatorName === CALC_TYPES.LOCAL ||
                calculatorName === CALC_TYPES.GLOBAL) && (
                <SecondStep
                  onChange={onChange}
                  onChangeDate={onChangeDate}
                  openDisclaimer={() => setIsDisclaimerOpened(true)}
                  openFileModal={openFileModal}
                  isDisabled={!draftId}
                  numberFilter={numberFilter}
                  data={selectedDraft}
                  years={years}
                  reportYear={reportYear}
                  dateDisable={!!initialDraft.reportDate}
                />
              )}
              {calculatorName === CALC_TYPES.CARBON && (
                <RegistrationCarbon
                  onChange={onChange}
                  onChangeDate={onChangeDate}
                  openDisclaimer={() => setIsDisclaimerOpened(true)}
                  openFileModal={openFileModal}
                  isDisabled={!draftId}
                  numberFilter={numberFilter}
                  data={selectedDraft}
                  years={years}
                  reportYear={reportYear}
                  dateDisable={!!initialDraft.reportDate}
                />
              )}
              {calculatorName === CALC_TYPES.PROJECT && (
                <RegistrationProject
                  onChange={onChange}
                  openDisclaimer={() => setIsDisclaimerOpened(true)}
                  openFileModal={openFileModal}
                  isDisabled={!draftId}
                  numberFilter={numberFilter}
                  data={selectedDraft}
                  dateDisable={!!initialDraft.reportDate}
                />
              )}
              {calculatorName === CALC_TYPES.CUSTOM && (
                <RegistrationCustom
                  onChange={onChange}
                  onChangeDate={onChangeDate}
                  openDisclaimer={() => setIsDisclaimerOpened(true)}
                  openFileModal={openFileModal}
                  isDisabled={!draftId}
                  numberFilter={numberFilter}
                  data={selectedDraft}
                  years={years}
                  reportYear={reportYear}
                  dateDisable={!!initialDraft.reportDate}
                />
              )}
            </Form>
          )}
          {/* <PartialLoader loading={!draft} isLightContainer /> */}
          <div className={clsx(styles.navigation, styles.single)}>
            <Button
              variant="contained"
              onClick={goToNextPage}
              disabled={!validated}
            >
              NEXT
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
