import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";

import { CalculatorsContext } from "../../context";
import Chart from "../../components/chart/Chart";
import CalcModal from "../../components/CalcModal/CalcModal";
import styles from "../pages.module.scss";

import {
  SelfAssessmentDisclaimerText,
  SelfAssessmentInfoText,
  CalculatorsListData,
} from "../../constants";

const Start = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { fullChartData, calculatorName } = useContext(CalculatorsContext);
  const [isDisclaimerOpened, setIsDisclaimerOpened] = useState(false);

  const openDisclaimer = () => {
    setIsDisclaimerOpened(true);
  };
  const closeDisclaimer = () => {
    setIsDisclaimerOpened(false);
  };
  const openCalculator = () => {
    const url =
      pathname === "solo_start" ? "/solo_registration" : "/registration";
    navigate(url);
  };

  useEffect(() => {
    if (!calculatorName) return;
    if (!CalculatorsListData[calculatorName]) navigate("/404");
  }, []);

  return (
    <Grid
      item
      container
      alignItems="stretch"
      justifyContent="center"
      className={styles.container}
    >
      <div className={styles.chartArea}>
        <Chart chartData={fullChartData} hasDesktopFormat />
      </div>
      <Grid item container direction="column" className={styles.textArea}>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: SelfAssessmentInfoText[calculatorName],
          }}
        />
        <Button form="rounded" variant="contained" onClick={openDisclaimer}>
          Start self-assessment
        </Button>
      </Grid>

      <CalcModal
        opened={isDisclaimerOpened}
        closeModalFunc={closeDisclaimer}
        okFunc={openCalculator}
        headerContent="Disclaimer"
        okBtnText="Continue"
        needAgreement={true}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: SelfAssessmentDisclaimerText[calculatorName]
              ? SelfAssessmentDisclaimerText[calculatorName]
              : SelfAssessmentDisclaimerText.base,
          }}
        />
      </CalcModal>
    </Grid>
  );
};

export default Start;
