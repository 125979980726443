import { SET_APP_LOADING, SET_APP_LOADED } from "../types";

const appReducer = (state, action) => {
  switch (action.type) {
    case SET_APP_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_APP_LOADED:
      return {
        ...state,
        loaded: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
