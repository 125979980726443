import React, { useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { LOGOUT_USER } from "../api";
import { UserContext } from "../context";
import { logoutUserHandler } from "../utils";

const Logout = () => {
  const { emptyUserData } = useContext(UserContext);
  const [logoutUser] = useMutation(LOGOUT_USER);

  const logOut = async () => {
    logoutUserHandler(logoutUser);
  };

  useEffect(() => {
    emptyUserData();
    logOut();
    window.location = process.env.REACT_APP_SSO_SIGN_IN;
  });
  return <></>;
};

export default Logout;
