import { CalculatorDescriptions } from "../../constants";

const calculatorNames = {
  scope1: ["Carbon Footprint Calculator", "Company Calculator", "Scope 1"],
  scope2: ["Carbon Footprint Calculator", "Company Calculator", "Scope 2"],
  scope3: ["Carbon Footprint Calculator", "Company Calculator", "Scope 3"],
  global: ["Global TT ESG calculator"],
  custom_report: ["Customizable Reporting"],
};

const getCalculatorDescription = ({ pathname = "", question } = {}) => {
  if (question) {
    return question.categories.map((name, index) => ({
      name,
      content: CalculatorDescriptions[name],
      expanded: index === question.categories.length - 1,
    }));
  }

  const result = Object.keys(calculatorNames).reduce((res, key) => {
    if (pathname.includes(key)) {
      calculatorNames[key].forEach((name) => {
        res.push({
          name,
          content: CalculatorDescriptions[name],
          expanded: false,
        });
      });
    }
    return res;
  }, []);

  result[result.length - 1].expanded = true;
  return result;
};

export default getCalculatorDescription;
