/* eslint-disable no-unused-vars, camelcase */
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";

import { Modal, TextInput } from "tt-ui-kit";
import { Grid, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as DeleteSvg } from "../../../assets/icons/delete2.svg";
import Form from "../../../components/form/Form";
import Pagination from "../../../components/pagination/Pagination";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import QuestionButton from "../../../components/questionButton/QuestionButton";
import { UserContext } from "../../../context";
import {
  CREATE_CALC_QUESTION,
  UPDATE_CALC_QUESTION,
  DELETE_CALC_QUESTION,
  SET_CALC_ANSWER,
  GET_CALC_QUESTION,
} from "../../../api";
import styles from "./CustomReport.module.scss";
import FileUploader from "../../../components/fileUploader/FileUploader";
import ModalCreateQuestion from "./ModalCreateQuestion";

const CustomReport = ({
  questionIndex,
  setQuestionIndex,
  setQuestionCount,
  setAccordionItems,
  calculatorContainerRef,
}) => {
  const { draftId } = useContext(UserContext);

  const { pathname } = useLocation();
  const { pageNum } = useParams();
  const navigate = useNavigate();

  const [createCalcQuestion, { loading: loadingCreate, data: createCalcData }] =
    useMutation(CREATE_CALC_QUESTION);
  const [updateCalcQuestion, { loading: loadingUpdate, data: updateCalcData }] =
    useMutation(UPDATE_CALC_QUESTION);
  const [deleteCalcQuestion, { loading: loadingDelete, data: deleteCalcData }] =
    useMutation(DELETE_CALC_QUESTION);
  const [
    setCalcAnswer,
    { loading: loadingSetAnswer, data: setCalcAnswerData },
  ] = useMutation(SET_CALC_ANSWER);
  const [getCalcQuestion, { loading: loadingData, data: calcData }] =
    useLazyQuery(GET_CALC_QUESTION, {
      variables: {
        calc_tt_draft_id: draftId,
        first: 1,
        page: parseInt(pageNum, 10),
      },
    });

  const [questionsList, setQuestionsList] = useState([]);
  const [topicsList, setTopicsList] = useState([]);
  const [topTopic, setTopTopic] = useState(null);
  const [paginatorInfo, setPaginatorInfo] = useState(null);
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [parentTopicId, setParentTopicId] = useState(null);
  const [editQuestionText, setEditQuestionText] = useState("");
  const [isQuestionEditModalOpen, setIsQuestionEditModalOpen] = useState(false);
  const [isQuestionCreateModalOpen, setIsQuestionCreateModalOpen] =
    useState(false);

  // useEffect(() => {
  //   console.log({ questionsList, topicsList });
  // }, [questionsList, topicsList]);

  const isLoading =
    loadingCreate ||
    loadingUpdate ||
    loadingDelete ||
    loadingSetAnswer ||
    loadingData;

  useEffect(() => {
    getCalcQuestion();
  }, [pageNum]);

  const openQuestionModal = (id, action) => {
    if (action === "create") {
      setParentTopicId(id);
      setIsQuestionCreateModalOpen(true);
    } else {
      setEditQuestionId(id);
      setIsQuestionEditModalOpen(true);
    }
  };

  // useEffect(() => {
  //   if (!updateCalcData?.updateCalcQuestion) return;
  //   console.log(updateCalcData?.updateCalcQuestion);
  //   setCalcQuestion(updateCalcData?.updateCalcQuestion);
  // }, [updateCalcData]);

  useEffect(() => {
    if (!deleteCalcData?.deleteCalcQuestions) return;
    getCalcQuestion();
  }, [deleteCalcData]);

  // useEffect(() => {
  //   if (!setCalcAnswerData?.setCalcAnswer) return;
  //   console.log(setCalcAnswerData?.setCalcAnswer);
  //   setCalcQuestion(setCalcAnswerData?.setCalcAnswer);
  // }, [setCalcAnswerData]);

  useEffect(() => {
    if (!calcData?.getCalcQuestions) return;
    const { questions, topics } = JSON.parse(calcData?.getCalcQuestions.data);
    setQuestionsList(questions);
    setTopicsList(topics);
    setTopTopic(topics.find((topic) => !topic.parent_topic_id));
    setPaginatorInfo(calcData?.getCalcQuestions.paginatorInfo);
  }, [calcData]);

  const addItem = (addArgs) => {
    const { typeItem, parentId, payload, isNavigate } = addArgs;
    createCalcQuestion({
      variables: {
        input: {
          calc_tt_draft_id: draftId,
          data: JSON.stringify({
            type: typeItem,
            parent_topic_id: parentId,
            ...payload,
          }),
        },
      },
    })
      .then((newData) => {
        const { id, type, parent_topic_id } = JSON.parse(
          newData?.data.createCalcQuestion.data
        );
        if (type === "question") {
          const newQuestions = [
            ...questionsList,
            {
              id,
              custom_report_topic_id: parent_topic_id,
              question_text: payload?.question_text,
            },
          ];
          setQuestionsList(newQuestions);
          setEditQuestionId(id);
        }
        if (type === "topic") {
          const newTopics = [...topicsList, { id, parent_topic_id }];
          setTopicsList(newTopics);
          setTopTopic(newTopics.find((topic) => !topic.parentId));
        }
      })
      .finally(() => {
        if (isNavigate) {
          const url = pathname.replace(
            pageNum,
            parseInt(paginatorInfo.total, 10) + 1
          );
          navigate(url);
        }
      });
  };

  const deleteItem = (type, itemId) => {
    deleteCalcQuestion({
      variables: {
        input: {
          calc_tt_draft_id: draftId,
          data: JSON.stringify({ type, id: itemId }),
        },
      },
    });
  };

  useEffect(() => {
    if (calcData && !topicsList?.length) addItem({ typeItem: "topic" });
  }, [topicsList]);

  const createNewQuestion = () => {
    addItem({
      typeItem: "question",
      parentId: parentTopicId,
      payload: { question_text: editQuestionText },
    });
  };

  /* update json data example:
    {
        "calc_tt_draft_id": "33333333-4306-4ad9-a5e8-f2b5e42044e9",
        "data": {
            topics: [
                { id: "e7c23702-0064-4518-9fa5-4b071f61cbaa", topic_text: "text2" }
            ],
            questions: [
                { id: "10a745f0-c60f-483d-9403-e335cb638ae2", question_text: "text" }
                { id: "7810f57c-028e-47af-848c-d49af98e2b02", question_text: "text1", answer_text: "text1" },
            ]
        }
    }
    */
  const saveDraftData = () => {
    updateCalcQuestion({
      variables: {
        input: {
          calc_tt_draft_id: draftId,
          data: JSON.stringify({
            topics: topicsList.filter((t) => t.edited),
            questions: questionsList.filter((q) => q.edited),
          }),
        },
      },
    });
  };

  const onNextClick = () => {
    const url = pathname.replace(pageNum, parseInt(pageNum, 10) + 1);
    navigate(url);
  };
  const onFinishClick = () => {
    navigate("/drafts");
  };
  const onBackClick = () => {
    const url = pathname.replace(pageNum, parseInt(pageNum, 10) - 1);
    navigate(url);
  };

  const changeTopic = (e, topicId) => {
    const newTopic = topicsList.find((topic) => topic.id === topicId);
    newTopic.topic_text = e.target.value;
    newTopic.edited = true;
    setTopicsList([
      ...topicsList.filter((topic) => topic.id !== topicId),
      newTopic,
    ]);
  };

  const changeQuestion = (e, questionId) => {
    const newQuestion = questionsList.find(
      (question) => question.id === questionId
    );
    newQuestion.question_text = e.target.value;
    newQuestion.edited = true;
    setQuestionsList([
      ...questionsList.filter((question) => question.id !== questionId),
      newQuestion,
    ]);
  };

  const getText = (type, id) => {
    if (type === "topic") {
      return topicsList.find((topic) => topic.id === id)?.topic_text ?? "";
    }
    if (type === "question") {
      return (
        questionsList.find((question) => question.id === id)?.question_text ??
        ""
      );
    }
    return "";
  };

  const unsaved =
    topicsList.some((topic) => topic.edited) ||
    questionsList.some((question) => question.edited);

  // TODO generate answer api
  const generateAnswer = (id) => {};

  // console.log({ questionsList, topicsList, topTopic, paginatorInfo });

  // console.log(
  //   questionsList?.filter(
  //     (question) =>
  //       question.parent_topic_id === topTopic.id && !!question.question_text
  //   )
  // );

  return (
    <>
      <ModalCreateQuestion
        params={{
          addItem,
          editQuestionId,
          parentTopicId,
          generateAnswer,
          isOpen: isQuestionCreateModalOpen,
          setIsOpen: setIsQuestionCreateModalOpen,
        }}
      />
      <Modal
        open={isQuestionEditModalOpen}
        onClose={() => setIsQuestionEditModalOpen(false)}
        closeOnlyByControls
        title={
          <div className={styles.headerModal}>
            <div>View question</div>
            <div>
              <Button
                variant="text"
                onClick={() => deleteItem("question", editQuestionId)}
                startIcon={<DeleteSvg fontSize="small" />}
                className={styles.modalDeleteButton}
                disabled={isLoading}
              >
                Delete question
              </Button>
            </div>
          </div>
        }
        className={styles.modal}
        /* eslint-disable-next-line no-underscore-dangle */
        containerClassName={clsx(styles.__modalRoot, styles.modalWide)}
      >
        <div className={styles.modalContent}>
          <TextInput
            name="question"
            label="Question"
            className={styles.questionInput}
            inputProps={{ maxLength: 1800 }}
            value={getText("question", editQuestionId)}
            onChange={(e) => changeQuestion(e, editQuestionId)}
            multiline
            maxRows={10}
            minRows={3}
          />
        </div>
      </Modal>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        className={styles.formContainer}
      >
        <div>
          <div className={styles.formContainer}>
            <Form>
              <div className={styles.questionContainer}>
                {topTopic && (
                  <div className={styles.topicBlock}>
                    <TextInput
                      name="topic"
                      label="Topic"
                      className={styles.questionInput}
                      inputProps={{ maxLength: 600 }}
                      value={getText("topic", topTopic.id)}
                      onChange={(e) => changeTopic(e, topTopic.id)}
                      multiline
                      maxRows={8}
                      minRows={1}
                    />
                    <div className={styles.buttonBlock}>
                      <Button
                        variant="text"
                        onClick={() =>
                          addItem({
                            typeItem: "topic",
                            parentId: topTopic.id,
                          })
                        }
                        startIcon={<AddIcon />}
                        className={styles.questionButton}
                        disabled={isLoading}
                      >
                        Add subtopic
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => openQuestionModal(topTopic.id, "create")}
                        startIcon={<AddIcon />}
                        className={styles.questionButton}
                        disabled={isLoading}
                      >
                        Add question
                      </Button>
                    </div>
                    <div className={styles.questionContainer}>
                      {questionsList
                        ?.filter(
                          (question) =>
                            question.custom_report_topic_id === topTopic.id &&
                            !!question.question_text
                        )
                        .map((question) => (
                          <div
                            key={question.id}
                            className={styles.questionBlock}
                          >
                            <div>{question.question_text}</div>
                            <Button
                              variant="outlined"
                              onClick={() => openQuestionModal(question.id)}
                              className={styles.questionButton}
                              disabled={isLoading}
                            >
                              View question
                            </Button>
                          </div>
                        ))}
                    </div>
                    {/* <div className={styles.subtopicBlock}> */}
                    {topicsList
                      ?.filter((topic) => topic.parent_topic_id === topTopic.id)
                      .map((topic) => (
                        <div key={topic.id} className={styles.subtopicBlock}>
                          <TextInput
                            name="subtopic"
                            label="Subtopic"
                            className={styles.questionInput}
                            inputProps={{ maxLength: 600 }}
                            value={getText("topic", topic.id)}
                            onChange={(e) => changeTopic(e, topic.id)}
                            multiline
                            maxRows={8}
                            minRows={1}
                          />
                          <div className={styles.questionBlock}>
                            <IconButton
                              onClick={() => deleteItem("topic", topic.id)}
                              className={styles.questionButton}
                              disabled={isLoading}
                            >
                              <DeleteSvg fontSize="small" />
                            </IconButton>
                            <Button
                              variant="text"
                              onClick={() =>
                                openQuestionModal(topic.id, "create")
                              }
                              startIcon={<AddIcon />}
                              className={styles.questionButton}
                              disabled={isLoading}
                            >
                              Add question
                            </Button>
                          </div>
                          <div className={styles.questionContainer}>
                            {questionsList
                              ?.filter(
                                (question) =>
                                  question.custom_report_topic_id === topic.id
                              )
                              .map((question) => (
                                <div
                                  key={question.id}
                                  className={styles.questionBlock}
                                >
                                  <div>{question.question_text}</div>
                                  <Button
                                    variant="outlined"
                                    onClick={() =>
                                      openQuestionModal(question.id)
                                    }
                                    disabled={isLoading}
                                    className={styles.questionButton}
                                  >
                                    View question
                                  </Button>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                  // </div>
                )}
              </div>
            </Form>
          </div>
        </div>
        <div className={styles.navigation}>
          <Button
            variant="outlined"
            onClick={onBackClick}
            disabled={!paginatorInfo || paginatorInfo?.currentPage <= 1}
          >
            Back
          </Button>
          {questionsList.some((q) => !!q.question_text) && (
            <Button
              variant="outlined"
              onClick={generateAnswer}
              disabled={paginatorInfo?.total !== paginatorInfo?.currentPage}
            >
              Generate answer
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={() => addItem({ typeItem: "question", isNavigate: true })}
            disabled={
              unsaved || paginatorInfo?.total !== paginatorInfo?.currentPage
            }
          >
            Add topic
          </Button>
          <Pagination
            activePage={paginatorInfo?.currentPage ?? 1}
            pagesCount={paginatorInfo?.total ?? 1}
          />
          {unsaved ? (
            <Button variant="contained" onClick={saveDraftData}>
              Save
            </Button>
          ) : (
            <>
              {paginatorInfo?.currentPage < paginatorInfo?.total && (
                <Button
                  variant="contained"
                  onClick={onNextClick}
                  disabled={isLoading}
                >
                  Next
                </Button>
              )}
              {paginatorInfo?.currentPage >= paginatorInfo?.total && (
                <Button
                  variant="contained"
                  onClick={onFinishClick}
                  disabled={isLoading}
                >
                  Finish
                </Button>
              )}
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

CustomReport.propTypes = {
  setQuestionIndex: PropTypes.func,
  questionIndex: PropTypes.number,
  setQuestionCount: PropTypes.func,
  setAccordionItems: PropTypes.func,
  calculatorContainerRef: PropTypes.any,
};

export default CustomReport;
