import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";

import styles from "./Form.module.scss";

const Form = ({
  children,
  headerContent,
  className,
  headerClick,
  onSubmit,
}) => {
  const onFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit(e);
  };

  return (
    <form className={clsx(styles.form, className)} onSubmit={onFormSubmit}>
      <h1 className={headerClick ? styles.clickable : ""} onClick={headerClick}>
        {headerContent}
      </h1>
      {children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node,
  headerContent: PropTypes.any,
  className: PropTypes.string,
  headerClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  children: null,
  headerContent: "",
  className: "",
  headerClick: null,
  onSubmit: () => {},
};

export default Form;
