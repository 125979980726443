import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "@mui/material";
import { TextInput } from "tt-ui-kit";
import styles from "./SecondStep.module.scss";
import MuiDatePicker from "../../components/muiDatePicker/MuiDatePicker";
import { ReactComponent as ConfDocSvg } from "../../assets/icons/confDoc.svg";

const RegistrationCustom = ({
  onChange,
  onChangeDate,
  openDisclaimer,
  openFileModal,
  data,
  // years,
  reportYear,
  dateDisable,
}) => (
  <>
    <Button
      className={styles.disclaimerBtn}
      size="small"
      color="lightBlue"
      onClick={openDisclaimer}
    >
      Financial data*
    </Button>
    <TextInput
      className={clsx(styles.input, styles.wide)}
      label="Name *"
      name="name"
      value={((data && data.name) ?? "").toString()}
      onChange={onChange}
    />
    <MuiDatePicker
      className={clsx(styles.input, styles.wide)}
      label="Reporting year *"
      name="reportDate"
      value={reportYear ? new Date(reportYear, 1, 1) : null}
      onChange={onChangeDate}
      minDate={new Date(2000, 1, 1)}
      maxDate={new Date(new Date().getFullYear(), 11, 31)}
      onlyYear={true}
      disabled={dateDisable}
      type="number"
    />
    <Button
      className={clsx(styles.docBtn, styles.normal)}
      startIcon={<ConfDocSvg />}
      onClick={openFileModal}
    >
      CONFIRMATION DOC
    </Button>
  </>
);

RegistrationCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  openDisclaimer: PropTypes.func.isRequired,
  openFileModal: PropTypes.func.isRequired,
  numberFilter: PropTypes.instanceOf(RegExp),
  data: PropTypes.object,
  years: PropTypes.array,
  reportYear: PropTypes.number,
  dateDisable: PropTypes.bool,
};

export default RegistrationCustom;
