import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Grid, Button, Tooltip } from "@mui/material";
import {
  CalculatorsContext,
  UserContext,
  ApiMethodContext,
} from "../../../context";
import { UPDATE_DRAFT } from "../../../api";

import Form from "../../../components/form/Form";
import Pagination from "../../../components/pagination/Pagination";

import AddButton from "./AddButton";
import Component6Fields from "./components/Component6Fields";
import QuestionButton from "../../../components/questionButton/QuestionButton";

import { ReactComponent as HelpSvg } from "../../../assets/icons/help.svg";
import styles from "./index.module.scss";
import CalcModal from "../../../components/CalcModal/CalcModal";
import FileUploader from "../../../components/fileUploader/FileUploader";
import { initCompanyAnswer } from "../../../utils";
import { CompanyQuestionsEnabledFields } from "../../../constants";
import CalculationCost from "../../../components/calculationCost/CalculationCost";

const Company = ({ scope, setQuestionIndex }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { idx } = useParams();

  const [updateDraft] = useMutation(UPDATE_DRAFT);

  const { saveDraftData, completeDraft } = useContext(ApiMethodContext);
  const { draftId } = useContext(UserContext);
  const {
    company,
    chartData,
    selectedCalculators,
    setCompanyData,
    setCalculatorsList,
    companyQuestions,
    isAnyAnswerExists,
  } = useContext(CalculatorsContext);

  const [isFileModalOpened, setIsFileModalOpened] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [index, setIndex] = useState(0);
  // const [scopeQuestions, setScopeQuestions] = useState([]);

  const scopeQuestions =
    companyQuestions && companyQuestions[scope - 1]
      ? companyQuestions[scope - 1].scope
      : [];

  useEffect(() => {
    setIndex(idx - 1);
  }, [idx]);

  useEffect(() => {
    const prev = scope > 1 ? [...Array(scope - 1).keys()] : [];
    const count = prev.reduce(
      (res, v) => res + companyQuestions[v].scope.length,
      0
    );
    setQuestionIndex(count + index);
  }, [index, scope]);

  const onSaveAnswer = async () => {
    const answers = Object.keys(company).reduce((result, key) => {
      const components = company[key]
        .filter((component) => component.filled)
        .map((component) => {
          const cloned = { ...component };
          delete cloned.filled;
          delete cloned.isNotAvailable;
          return cloned;
        });

      return {
        ...result,
        [key]: components,
      };
    }, {});

    try {
      await updateDraft({
        variables: {
          input: {
            id: draftId,
            company_answers: JSON.stringify(answers),
            last_position: JSON.stringify(pathname),
            state_graphics: JSON.stringify(chartData),
          },
        },
      });
    } catch (_) {
      // TODO: показывать ошибку, если запрос не прошёл
      // см. комментарий в Local.jsx
    }
  };

  const getCalcIndex = () =>
    selectedCalculators.findIndex(
      (calculator) => calculator.name === `scope${scope}`
    );

  const activityFilledCount = () => {
    if (
      !scopeQuestions ||
      !scopeQuestions[index] ||
      !company[scopeQuestions[index].code]
    )
      return -1;

    const { code } = scopeQuestions[index];
    const activities = [...company[code]];
    return activities.filter((act) => {
      const keys = Object.keys(act).filter(
        (ckey) => !["isNotAvailable", "info", "filled"].includes(ckey)
      );
      return !!act.info || keys.reduce((res, k) => res && !!act[k], true);
    }).length;
  };

  /**
   * получение списка элементов из обьекта scopeQuestions
   * обьект иерархический - activities -> types -> units -> (additional || (size -> additional))
   * на вход подается компонент вопроса и тип выпадающего меню
   * возвращает список элементов выпадающего меню отпосительно выбранного элемента старшего уровня
   * или старший элемент единственный на весь вопрос
   * @param {*} component - состояние ответа - state.company[<questionCode>][index]
   * @param {*} input - имя выпадающего меню
   * @returns {[{ id, name }]}
   */
  const getItemsList = (component, input) => {
    if (scopeQuestions.length === 0 || scopeQuestions.length - 1 < index)
      return [];
    const newComponent = { ...component };

    // activities
    newComponent.activities = [...scopeQuestions[index].activities];

    if (input === "activity" && newComponent.activities)
      return newComponent.activities.map((el) => {
        const item = { id: el.id, name: el.name };
        return item;
      });

    if (
      !newComponent.activity &&
      newComponent.activities &&
      newComponent.activities.length === 1
    ) {
      newComponent.activity = newComponent.activities.find((el) => el)?.id;
    }

    if (!newComponent.activity) return [];

    newComponent.activityObj = newComponent.activities.find(
      (el) => el.id === newComponent.activity
    );

    // types
    newComponent.types = [...newComponent.activityObj.types];

    if (input === "type" && newComponent.types)
      return newComponent.types.map((el) => {
        const item = { id: el.id, name: el.name };
        return item;
      });

    if (
      !newComponent.type &&
      newComponent.types &&
      newComponent.types.length === 1
    ) {
      newComponent.type = newComponent.types.find((el) => el)?.id;
    }

    if (!newComponent.type) return [];

    newComponent.typeObj = newComponent.types.find(
      (el) => el.id === newComponent.type
    );

    // units
    newComponent.units = [...newComponent.typeObj.units];

    if (input === "unit" && newComponent.units)
      return newComponent.units.map((el) => {
        const item = { id: el.id, name: el.name };
        return item;
      });

    if (
      !newComponent.unit &&
      newComponent.units &&
      newComponent.units.length === 1
    ) {
      newComponent.unit = newComponent.units.find((el) => el)?.id;
    }

    if (!newComponent.unit) return [];

    newComponent.unitObj = newComponent.units.find(
      (el) => el.id === newComponent.unit
    );

    // size
    newComponent.sizes = [...newComponent.unitObj.size];

    if (input === "size" && newComponent.sizes)
      return newComponent.sizes.map((el) => {
        const item = { id: el.id, name: el.name };
        return item;
      });

    // additional_u
    if (input === "additional_u") {
      newComponent.additional = Object.keys(newComponent.unitObj.additional)
        .filter(
          (key) =>
            !!newComponent.unitObj.additional[key] && key !== "__typename"
        )
        .map((key) => {
          const obj = { name: newComponent.unitObj.additional[key], id: key };
          return obj;
        });
      return newComponent.additional;
    }
    if (!newComponent.size) return [];

    newComponent.sizeObj = newComponent.sizes.find(
      (el) => el.id === newComponent.size
    );

    // additional_s
    if (input === "additional_s") {
      newComponent.additional = Object.keys(newComponent.sizeObj.additional)
        .filter(
          (key) =>
            !!newComponent.sizeObj.additional[key] && key !== "__typename"
        )
        .map((key) => {
          const obj = { name: newComponent.sizeObj.additional[key], id: key };
          return obj;
        });
      return newComponent.additional;
    }
    return [];
  };

  /**
   * проверка является ли элемент единственным для вопроса
   * в некоторых вопросах требуется исключить такие выпадающие меню
   * @param {string} input - имя выпадающего меню
   * @returns {bool}
   */
  const isSingleItem = (component, input) =>
    getItemsList(component, input).length === 1;

  const getSingleItem = (component, input) => {
    const items = getItemsList(component, input);
    return items.length > 0 ? items[0] : {};
  };

  const onBackToListOfIssues = () => {
    navigate(`/drafts`);
  };

  const onBackClick = async () => {
    await onSaveAnswer();
    const calcIndex = getCalcIndex();
    if (index === 0) {
      if (calcIndex < 0) {
        setCalculatorsList([]);
        navigate("/");
      } else if (calcIndex === 0) {
        navigate("/drafts");
      } else {
        if (selectedCalculators[calcIndex - 1].name.includes("scope")) {
          setIndex(companyQuestions[scope - 2].scope.length - 1);
        }
        navigate(
          `${selectedCalculators[calcIndex - 1].clearUrl}/${
            companyQuestions[scope - 2].scope.length
          }`
        );
      }
    } else {
      // setIndex(index - 1);
      navigate(
        `${selectedCalculators[calcIndex].clearUrl}/${parseInt(idx, 10) - 1}`
      );
    }
  };

  const onNextClick = async () => {
    await onSaveAnswer();
    const calcIndex = getCalcIndex();
    if (index === scopeQuestions.length - 1) {
      if (calcIndex < 0) {
        setCalculatorsList([]);
        navigate("/");
      } else if (calcIndex === selectedCalculators.length - 1) {
        if (isAnyAnswerExists()) {
          await completeDraft();
          navigate(`/buy_credits/${draftId}`);
        } else navigate("/drafts");
      } else {
        if (selectedCalculators[calcIndex + 1].name.includes("scope")) {
          setIndex(0);
        }
        navigate(selectedCalculators[calcIndex + 1].url);
      }
    } else {
      // setIndex(index + 1);
      navigate(
        `${selectedCalculators[calcIndex].clearUrl}/${parseInt(idx, 10) + 1}`
      );
    }
  };

  const addActivity = () => {
    const { code } = scopeQuestions[index];
    const activities = [...company[code]];

    activities.push({ ...initCompanyAnswer(code) });
    setCompanyData(code, activities);

    setTimeout(() => {
      const activitiesElements = document.getElementsByClassName("activity");
      activitiesElements[activitiesElements.length - 1].scrollIntoView({
        behavior: "smooth",
      });
    });
  };

  const changeActivity = (name, actIndex, propName, propValue) => {
    const activities = [...company[name]];
    const component = activities[actIndex];
    const propN = propName.split("_")[0];
    const keys = Object.keys(component).filter(
      (key) => !["isNotAvailable", "info", "filled"].includes(key)
    );

    // очистка полей при изменении верхнего по иерархии поля
    if (propName === "activity") {
      const scopeId = scopeQuestions[index].activities.find(
        (activity) => activity.id === propValue
      )?.scopeId;
      component.scopeId = scopeId;
      if (component.type) component.type = "";
      if (component.unit) component.unit = "";
      if (component.size) component.size = "";
      if (component.additional) component.additional = "";
    }
    if (propName === "type") {
      if (component.unit) component.unit = "";
      if (component.size) component.size = "";
      if (component.additional) component.additional = "";
    }
    if (propName === "unit") {
      if (component.size) component.size = "";
      if (component.additional) component.additional = "";
    }
    if (propName === "size") {
      if (component.additional) component.additional = "";
    }

    component[propN] = propValue;

    // заполнение полей не вынесенных в форму для выбора пользователя
    // поле должно отсутствовать в конфигураторе вопросов
    // и быть единственным в обьекте
    if (
      !CompanyQuestionsEnabledFields[name].includes("activity") &&
      isSingleItem(component, "activity")
    ) {
      const single = scopeQuestions[index].activities[0];
      component.activity = single.id;
      component.scopeId = single.scopeId;
    }
    if (
      !CompanyQuestionsEnabledFields[name].includes("type") &&
      isSingleItem(component, "type") &&
      component.activity
    ) {
      const single = getItemsList(component, "type")[0];
      component.type = single.id;
    }
    if (
      !CompanyQuestionsEnabledFields[name].includes("unit") &&
      isSingleItem(component, "unit") &&
      component.activity &&
      component.type
    ) {
      const single = getItemsList(component, "unit")[0];
      component.unit = single.id;
    }

    component.filled =
      !!component.info ||
      keys.reduce(
        (res, key) => res && (!!component[key] || key === "info"),
        true
      );

    activities[actIndex] = component;
    setCompanyData(name, activities);
  };

  const removeActivity = () => {
    const { code } = scopeQuestions[index];
    const activities = [...company[code]].filter(
      (el, indx) => indx !== deleteIndex
    );
    setCompanyData(code, activities);
  };

  const isLastActivityFilled = () => {
    if (!scopeQuestions || !scopeQuestions[index]) return true;

    const { code } = scopeQuestions[index];
    const activities = [...company[code]];
    return activityFilledCount() < activities.length;
  };

  const closeFileModal = () => {
    setIsFileModalOpened(false);
  };

  // const openFileModal = () => {
  //   const { code } = scopeQuestions[index];
  //   getQuestionsFile({
  //     questionCode: code,
  //   });
  //   setIsFileModalOpened(true);
  // };

  const openDeleteModal = (indx) => {
    setDeleteIndex(indx);
    setIsDeleteModalOpened(true);
  };

  const closeDeleteModal = () => {
    setDeleteIndex(null);
    setIsDeleteModalOpened(false);
  };

  const getGuestionCode = () =>
    scopeQuestions && scopeQuestions[index] ? scopeQuestions[index].code : "";

  const renderFieldComponent = (
    name,
    type,
    component,
    componentIndex,
    typeName,
    units,
    label
  ) => (
    <Component6Fields
      key={componentIndex}
      index={componentIndex}
      name={name}
      component={component}
      typeName={typeName}
      isLast={componentIndex === company[name].length - 1}
      onChange={changeActivity}
      onRemove={openDeleteModal}
      getItemsList={getItemsList}
      getSingleItem={getSingleItem}
      isSingleItem={isSingleItem}
      label={label}
    />
  );

  return (
    <>
      <CalcModal
        opened={isDeleteModalOpened}
        closeModalFunc={closeDeleteModal}
        headerContent="Delete component"
        okBtnText="Delete"
        okFunc={removeActivity}
        needAgreement={false}
        backBtnText="Cancel"
      >
        Do you really want to delete activity №{deleteIndex + 1}?
      </CalcModal>
      <CalcModal
        opened={isFileModalOpened}
        closeModalFunc={closeFileModal}
        headerContent="Upload your confirmation docs"
        okBtnText="Ok"
        needAgreement={false}
        backBtnText="Back"
        backFunc={closeFileModal}
      >
        <FileUploader questionCode={getGuestionCode()} />
      </CalcModal>
      <Grid
        item
        container
        wrap="nowrap"
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        className={styles.formContainer}
      >
        <div>
          <div>
            <QuestionButton
              isAnsvered={activityFilledCount() > 0}
              type={"isAnsvered"}
            />
            <QuestionButton
              action={onBackToListOfIssues}
              type={"redirect"}
              label={"Back to list of issues"}
            />
            <QuestionButton
              action={saveDraftData}
              type={"save"}
              label={"Save & complete later"}
            />
          </div>
          <Form
            headerContent={
              <>
                <div
                  className={clsx(
                    styles.headerContainer,
                    styles.companyHeaderContainer
                  )}
                >
                  {scopeQuestions[index] &&
                    `${scopeQuestions[index].name.substring(
                      0,
                      scopeQuestions[index].name.lastIndexOf(" ")
                    )} `}
                  <span>
                    {scopeQuestions[index] &&
                      scopeQuestions[index].name.substring(
                        scopeQuestions[index].name.lastIndexOf(" ") + 1
                      )}
                    <div className={styles.icons}>
                      {scopeQuestions[index] &&
                        scopeQuestions[index].name.startsWith("WTT") && (
                          <Tooltip title="Well-to-tank" enterTouchDelay={0}>
                            <HelpSvg
                              className={clsx(styles.infoIcon, styles.topIcon)}
                            />
                          </Tooltip>
                        )}
                    </div>
                  </span>
                </div>
              </>
            }
          >
            {scope === "1" &&
              index === 0 &&
              company.scope_1_question_1.map((component, i) =>
                renderFieldComponent(
                  "scope_1_question_1", // "fuels",
                  "",
                  component,
                  i,
                  "Fuel"
                )
              )}
            {scope === "1" &&
              index === 1 &&
              company.scope_1_question_2.map((component, i) =>
                renderFieldComponent(
                  "scope_1_question_2", // "bioenergy",
                  "bioenergy",
                  component,
                  i,
                  "Fuel"
                )
              )}
            {scope === "1" &&
              index === 2 &&
              company.scope_1_question_3.map((component, i) =>
                renderFieldComponent(
                  "scope_1_question_3", // "refrigerant_and_other",
                  "refrigerant",
                  component,
                  i,
                  "Emission",
                  "Kg"
                )
              )}
            {scope === "1" &&
              index === 3 &&
              company.scope_1_question_4.map((component, i) =>
                renderFieldComponent(
                  "scope_1_question_4", // "passenger_vehicles",
                  "vehicles",
                  component,
                  i,
                  "Type"
                )
              )}
            {scope === "1" &&
              index === 4 &&
              company.scope_1_question_5.map((component, i) =>
                renderFieldComponent(
                  "scope_1_question_5", // "delivery_vehicles",
                  "vehicles",
                  component,
                  i,
                  "Type"
                )
              )}
            {scope === "2" &&
              index === 0 &&
              company.scope_2_question_1.map((component, i) =>
                renderFieldComponent(
                  "scope_2_question_1", // "electricity",
                  "electricity",
                  component,
                  i,
                  "Country",
                  "KWh"
                )
              )}
            {scope === "2" &&
              index === 1 &&
              company.scope_2_question_2.map((component, i) =>
                renderFieldComponent(
                  "scope_2_question_2", // "heat_and_steam",
                  "heat",
                  component,
                  i,
                  "Type",
                  "KWh"
                )
              )}
            {scope === "3" &&
              index === 0 &&
              company.scope_3_question_1.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_1", // "wtt_fuels",
                  "fuels",
                  component,
                  i,
                  "Fuel"
                )
              )}
            {scope === "3" &&
              index === 1 &&
              company.scope_3_question_2.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_2", // "wtt_bioenergy",
                  "bioenergy",
                  component,
                  i,
                  "Fuel"
                )
              )}
            {scope === "3" &&
              index === 2 &&
              company.scope_3_question_3.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_3", // "wtt_uk_and_overseas_elec",
                  "wtt_electricity",
                  component,
                  i,
                  "Country",
                  "KWh"
                )
              )}
            {scope === "3" &&
              index === 3 &&
              company.scope_3_question_4.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_4", // "wtt_heat_and_steam",
                  "wtt_heat",
                  component,
                  i,
                  "Type",
                  "KWh"
                )
              )}
            {scope === "3" &&
              index === 4 &&
              company.scope_3_question_5.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_5", // "water_supply",
                  "water",
                  component,
                  i,
                  null,
                  "",
                  "Unit"
                )
              )}
            {scope === "3" &&
              index === 5 &&
              company.scope_3_question_6.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_6", // "water_treatment",
                  "water",
                  component,
                  i,
                  null,
                  "",
                  "Unit"
                )
              )}
            {scope === "3" &&
              index === 6 &&
              company.scope_3_question_7.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_7", // "material_use",
                  "material",
                  component,
                  i,
                  "Type",
                  "tonnes"
                )
              )}
            {scope === "3" &&
              index === 7 &&
              company.scope_3_question_8.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_8", // "waste_disposal",
                  "waste",
                  component,
                  i,
                  "Type",
                  "tonnes"
                )
              )}
            {scope === "3" &&
              index === 8 &&
              company.scope_3_question_9.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_9", // "business_travel_air",
                  "air",
                  component,
                  i,
                  "Class",
                  "passenger/km"
                )
              )}
            {scope === "3" &&
              index === 9 &&
              company.scope_3_question_10.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_10", // "wtt_business_travel_air",
                  "air",
                  component,
                  i,
                  "Class",
                  "passenger/km"
                )
              )}
            {scope === "3" &&
              index === 10 &&
              company.scope_3_question_11.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_11", // "business_travel_sea",
                  "sea",
                  component,
                  i,
                  "Type",
                  "passenger/km"
                )
              )}
            {scope === "3" &&
              index === 11 &&
              company.scope_3_question_12.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_12", // "wtt_business_travel_sea",
                  "sea",
                  component,
                  i,
                  "Type",
                  "passenger/km"
                )
              )}
            {scope === "3" &&
              index === 12 &&
              company.scope_3_question_13.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_13", // "business_travel_land",
                  "land",
                  component,
                  i,
                  "Type"
                )
              )}
            {scope === "3" &&
              index === 13 &&
              company.scope_3_question_14.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_14", // "wtt_pass_vehs_and_travel_land",
                  "land",
                  component,
                  i,
                  "Type"
                )
              )}
            {scope === "3" &&
              index === 14 &&
              company.scope_3_question_15.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_15", // "freighting_goods",
                  "goods",
                  component,
                  i,
                  "Type"
                )
              )}
            {scope === "3" &&
              index === 15 &&
              company.scope_3_question_16.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_16", // "wtt_delivery_vehs_and_freight",
                  "goods",
                  component,
                  i,
                  "Type"
                )
              )}
            {scope === "3" &&
              index === 16 &&
              company.scope_3_question_17.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_17", // "hotel_stay",
                  "hotel",
                  component,
                  i,
                  "Country",
                  "Room per night"
                )
              )}
            {scope === "3" &&
              index === 17 &&
              company.scope_3_question_18.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_18", // "managed_assets_electricity",
                  "hotel",
                  component,
                  i,
                  "Country",
                  "kWh"
                )
              )}
            {scope === "3" &&
              index === 18 &&
              company.scope_3_question_19.map((component, i) =>
                renderFieldComponent(
                  "scope_3_question_19", // "managed_assets_vehicles",
                  "assets_vehicles",
                  component,
                  i,
                  "Type"
                )
              )}
            <div className={styles.docButtonCont}>
              <FileUploader questionCode={getGuestionCode()} disabled={false} />
              <AddButton
                className={styles.addButton}
                text="Add Activity"
                onClick={addActivity}
                isDisabled={isLastActivityFilled()}
              />
            </div>
          </Form>
          <CalculationCost />
        </div>
        <div className={styles.navigation}>
          <Button
            variant="outlined"
            style={{
              visibility:
                getCalcIndex() > 0 || index > 0 ? "visible" : "hidden",
            }}
            onClick={onBackClick}
          >
            BACK
          </Button>

          <Pagination
            activePage={index + 1}
            pagesCount={!scopeQuestions ? 0 : scopeQuestions.length}
          />

          <Button variant="contained" onClick={onNextClick}>
            {getCalcIndex() >= selectedCalculators.length - 1 &&
            index === Object.keys(companyQuestions[scope - 1].scope).length - 1
              ? "FINISH"
              : "NEXT"}
          </Button>
        </div>
      </Grid>
    </>
  );
};

Company.propTypes = {
  scope: PropTypes.string.isRequired,
  setQuestionIndex: PropTypes.func,
};

Company.defaultProps = {
  setQuestionIndex: () => {},
};

export default Company;
