import React from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./AccordionGroup.module.scss";

const AccordionGroup = ({ items, className, onAccordionClick }) => {
  const handleChange = (item) => (event, newActive) => {
    onAccordionClick(newActive, item);
  };

  return (
    <div className={className}>
      {items.map((item) => (
        <Accordion
          key={item.name}
          expanded={item.expanded}
          onChange={handleChange(item.name)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={item.nested ? styles.nested : ""}>{item.name}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              className={item.nested ? styles.nested : ""}
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
            {item.children &&
              item.children.map((child) => (
                <Accordion
                  key={child.name}
                  expanded={child.expanded}
                  onChange={handleChange(child.name)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div>{child.name}</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div dangerouslySetInnerHTML={{ __html: child.content }} />
                  </AccordionDetails>
                </Accordion>
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

AccordionGroup.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  onAccordionClick: PropTypes.func,
};

AccordionGroup.defaultProps = {
  items: [],
  className: "",
  onAccordionClick: () => {},
};

export default AccordionGroup;
