import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

import styles from "./index.module.scss";
import FileUploader from "../../../components/fileUploader/FileUploader";

const BoolQuestion = ({ text, answer, setAnswer, fileCode }) => (
  <div className={styles.question}>
    <div
      className={styles.questionText}
      dangerouslySetInnerHTML={{ __html: text }}
    />
    <div className={styles.footerAnswer}>
      <div className={styles.footerContainer}>
        <FileUploader questionCode={fileCode} disabled={false} />
      </div>
      <div className={styles.boolAnswers}>
        <Button
          variant={answer === 1 ? "contained" : "outlined"}
          onClick={() => setAnswer(1)}
        >
          YES
        </Button>
        <Button
          variant={answer === 0 ? "contained" : "outlined"}
          onClick={() => setAnswer(0)}
        >
          NO
        </Button>
      </div>
    </div>
  </div>
);

BoolQuestion.propTypes = {
  answer: PropTypes.number,
  text: PropTypes.string.isRequired,
  setAnswer: PropTypes.func.isRequired,
  openFileModal: PropTypes.func,
  fileCode: PropTypes.string,
};

export default BoolQuestion;
